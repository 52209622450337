import React from 'react';
import { useSelector } from 'react-redux';
import Loader from './Loader';

function SignIn({change, signin, loading}) {
    const {language} = useSelector(state => state.language)

    return(
        <form>
            <div className="mb-3">
                <label for="login-input" className="form-label">Login</label>
                <input onChange={change.bind(null)} type="text" className="form-control" id="login-input" name="login"/>
            </div>
            <div className="mb-3">
                <label for="password-input" className="form-label">{language==="en" ? <>Password</> : <>Hasło</>}</label>
                <input onChange={change.bind(null)} type="password" className="form-control" id="password-input" name="password"/>
            </div>
            <button onClick={signin.bind(null)} type="submit" className="btn btn-primary">{language==="en" ? <>{loading ? <Loader /> : <>Sign in</>}</> : <>{loading ? <Loader /> :<>Zaloguj się</>}</>}</button>
            <a href={`${window.location.href}/reminder`} >
                {language==="en" ?
                <>Don't remember your password? Click here</> :
                <>Nie pamiętasz hasła? Kliknij tutaj</>}</a>
        </form>
    )
}

export default SignIn