import React from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../helpers/cookies';
import { baseURL } from '../apis';
import { getMachine, addMachine, updateMachine, deleteMachine, getTypes } from '../actions';
import Loader from './Loader';
import Message from './Message';
import TypesList from './TypesList';


class MachineForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toEdit: false,
            loaded: false,
            machineId: '',
            brand: 'Minerva',
            model: '',
            type: '',
            quantity: '',
            marketPrice: '',
            deliveryDate: 0,
            previousDelivery: 0,
            description: '',
            weight: '',
            EAN: '',
            boxLength: '',
            boxWidth: '',
            boxHeight: '',
            link: '',
            error: '',
            showMessage: false,
            message: '',
            types: [],
            PCN: '',
            opis: '',
            nettoWeight: ''
        }
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        try {
            await this.props.getTypes(token);
        this.setState({types: this.props.types})
        } catch(err) {
            console.log(err.response.data)
        }
        if(this.props.match && this.props.match.params.machineId) {
            try {
            await this.props.getMachine(this.props.match.params.machineId, token);
            this.setState({machineId: this.props.machine._id});
            this.setState({brand: this.props.machine.brand});
            this.setState({model: this.props.machine.model});
            this.setState({type: this.props.machine.type});
            this.setState({quantity: this.props.machine.quantity});
            if(this.props.machine.marketPrice!==0) {
                this.setState({marketPrice: this.props.machine.marketPrice});
            }
            this.setState({deliveryDate: this.props.machine.deliveryDate});
            this.setState({description: this.props.machine.description});
            if(this.props.machine.weight!==0) {
                this.setState({weight: this.props.machine.weight});
            }
            if(this.props.machine.nettoWeight!==0) {
                this.setState({nettoWeight: this.props.machine.nettoWeight});
            }
            if(this.props.machine.EAN!=="Not Applicable") {
                this.setState({EAN: this.props.machine.EAN});
            }
            if(this.props.machine.boxLength!==0) {
                this.setState({boxLength: this.props.machine.boxLength});
            }
            if(this.props.machine.boxWidth!==0) {
                this.setState({boxWidth: this.props.machine.boxWidth});
            }
            if(this.props.machine.boxHeight!==0) {
                this.setState({boxHeight: this.props.machine.boxHeight});
            }
            this.setState({link: this.props.machine.link});
            this.setState({PCN: this.props.machine.PCN});
            this.setState({opis: this.state.opis});

            

            this.setState({toEdit: true})
            } catch(err) {
                console.log(err.response.data)
            }
        }
        this.setState({loaded: true})

        let inputDate = document.getElementById('deliveryDate-input');
        let checkbox = document.getElementById('date-checkbox');
        if(this.props.machine.deliveryDate>0) {
            inputDate.valueAsNumber = this.props.machine.deliveryDate;
        } else {
            checkbox.checked = true;
            inputDate.setAttribute('disabled', true);
        }
        console.log(this.state.PCN)
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'brand':
                this.setState({brand: e.target.value});
                break;
            case 'model':
                this.setState({model: e.target.value});
                break;
            case 'type':
                this.setState({type: e.target.value});
                break;
            case 'quantity':
                let quantity = e.target.value.replace(',', '.')
                this.setState({quantity: quantity});
                break;
            case 'marketPrice':
                let string = e.target.value.replace(',', '.')
                this.setState({marketPrice: string});
                break;
            case 'deliveryDate':
                this.setState({deliveryDate: e.target.valueAsNumber})
                break;
            case 'date-checkbox':
                let deliveryDate = this.state.deliveryDate;
                let checkbox = document.getElementById('deliveryDate-input');
                if(e.target.checked) {
                    checkbox.setAttribute('disabled', 'true');
                    this.setState({previousDelivery: deliveryDate});
                    this.setState({deliveryDate: 0});
                } else {
                    checkbox.removeAttribute('disabled');
                    this.setState({deliveryDate: this.state.previousDelivery})
                    if(this.state.previousDelivery>0) {
                    checkbox.valueAsNumber = this.state.previousDelivery;
                    }
                }
                break;
            case 'description':
                this.setState({description: e.target.value});
                break;
            case 'weight':
                let weight = e.target.value.replace(',', '.')
                this.setState({weight: weight});
                break;
            case 'nettoWeight':
                    let nettoWeight = e.target.value.replace(',', '.')
                    this.setState({nettoWeight: nettoWeight});
                    break;
            case 'EAN':
                this.setState({EAN: e.target.value});
                break;
            case 'boxLength':
                let length = e.target.value.replace(',', '.')
                this.setState({boxLength: length});
                break;
            case 'boxWidth':
                let width = e.target.value.replace(',', '.')
                this.setState({boxWidth: width});
                break;
            case 'boxHeight':
                let height = e.target.value.replace(',', '.')
                this.setState({boxHeight: height});
                break;
            case 'link':
                this.setState({link: e.target.value});
                break;
            case 'PCN':
                this.setState({PCN: e.target.value});
                break;
            case 'opis':
                this.setState({opis: e.target.value});
                break;
            default:
                break;
        }

    }

    renderTypes = () => {
        const types = this.state.types

        return types.map(type => {
            if(type.name===this.state.type) {
            return(
                <option selected value={type._id}>{type.name}</option>
            )}
            else {
                return (
                    <option value={type._id}>{type.name}</option>
                )
            }
            })
    }

    clearForm = () => {
            this.setState({model: ''});
            this.setState({type: ''});
            this.setState({quantity: ''});
            this.setState({marketPrice: ''});
            this.setState({deliveryDate: 0});
            this.setState({previousDelivery: 0});
            this.setState({description: ''});
            this.setState({weight: ''});
            this.setState({EAN: ''});
            this.setState({boxLength: ''});
            this.setState({boxWidth: ''});
            this.setState({boxHeight: ''});
            this.setState({link: ''});
            this.setState({PCN: ''});
            this.setState({opis: ''});
            this.setState({nettoWeight: ''});
            let inputDate = document.getElementById('deliveryDate-input');
            inputDate.valueAsNumber = this.props.machine.deliveryDate;
    }

    handleAdd = async e => {
        e.preventDefault();

        let { brand, model, type, quantity, marketPrice, deliveryDate, description, EAN, weight, nettoWeight, boxLength, boxWidth, boxHeight, link, PCN, opis} = this.state;
        const token = getCookie('jwt_access_tkn');
        if(marketPrice==='') {
            marketPrice = 0
        }
        if(EAN==='') {
            EAN="Not Applicable"
        }
        if(weight==='') {
            weight=0;
        }
        if(nettoWeight==='') {
            nettoWeight=0;
        }
        if(boxLength==='') {
            boxLength=0
        }
        if(boxWidth==='') {
            boxWidth=0
        }
        if(boxHeight==='') {
            boxHeight=0
        }
        if(token) {
        try {
            await this.props.addMachine(brand, model, type, quantity, marketPrice, deliveryDate, description, EAN, weight, nettoWeight, boxLength, boxWidth, boxHeight, link, PCN, opis, token);
        if(this.props.language.language==="en") {
            this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl )}; 
        setTimeout( () => {
            window.location.replace(`${baseURL}/panel/machines`);
        }, 2000)
        } catch(err) {
        if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        
        }
    }

    }

    handleUpdate = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn') 
        if(token) {
        try {
            await this.props.updateMachine(this.state.machineId, this.state.brand, this.state.model, this.state.type, this.state.quantity, this.state.marketPrice, this.state.deliveryDate, this.state.description, this.state.EAN, this.state.weight, this.state.nettoWeight, this.state.boxLength, this.state.boxWidth, this.state.boxHeight, this.state.link, this.state.PCN, this.state.opis, token);
        if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl )};
        setTimeout( () => {
                window.location.replace(`${baseURL}/panel/machines`);
            }, 2000)
        } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        }
    }
    }

    handleDelete = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.deleteMachine(this.state.machineId, token)
                if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en) }
                else { this.showMessage(this.props.message.pl )};
                setTimeout( () => {
                    window.location.replace(`${baseURL}/panel/machines`);
                }, 5000)
            } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }            }
        }
    }


    render() {
        const { brand, model, quantity, marketPrice, deliveryDate, description, weight, nettoWeight, EAN, boxHeight, boxLength, boxWidth, link, PCN, opis} = this.state;
        const {language} = this.props.language;
        const content = (
        <>
            <form>
                <div className="mb-3">
                    <label for="brand-input" className="form-label">
                        {language==="en" ?
                        <>Machine brand</> :
                        <>Marka maszyny</>}
                        </label>
                    <input onChange={this.handleChange} type="text" name="brand" value={brand} className="form-control" id="brand-input"/>
                </div>
                <div className="mb-3">
                    <label for="model-input" className="form-label">
                    {language==="en" ?
                        <>Machine model</> :
                        <>Model maszyny</>}
                        </label>
                    <input onChange={this.handleChange} type="text" name="model" value={model} className="form-control" id="model-input"/>
                </div>
                <select name="type" onChange={this.handleChange} className="form-select" aria-label="Default select example">
                    {language==="en" ? <option value="">
                        Choose machine type 
                        </option> : 
                        <option value="">Wybierz rodzaj maszyny</option>}
                    <TypesList types={this.state.types} currentType={this.state.type}/>
                </select>
                <div className="mb-3">
                    <label for="quantity-input" className="form-label">
                    {language==="en" ?
                        <>Quantity</> :
                        <>Stan magazynowy</>}
                        </label>
                    <input onChange={this.handleChange} type="text" name="quantity" value={quantity} className="form-control" id="quantity-input"/>
                </div>
                <div className="mb-3">
                    <label for="marketPrice-input" className="form-label">
                    {language==="en" ?
                        <>Recommended Retail Price</> :
                        <>Sugerowana cena detaliczna</>}
                        </label>
                    <input onChange={this.handleChange} type="text" name="marketPrice" value={marketPrice} className="form-control" id="marketPrice-input"/>
                </div>
                <div className="mb-3">
                    <label for="deliveryDate-input" className="form-label">
                    {language==="en" ?
                        <>Next delivery date</> :
                        <>Planowana data dostawy</>}
                        </label>
                    <input onChange={this.handleChange} valueAsNumber={deliveryDate} type="date" name="deliveryDate" className="form-control" id="deliveryDate-input"/>
                </div>
                <div className="form-check form-switch">
                        <input onChange={this.handleChange} className="form-check-input" name="date-checkbox" type="checkbox" value="" id="date-checkbox"/>
                        <label className="form-check-label" for="date-checkbox">
                        {language==="en" ?
                        <>No data about next delivery date</> :
                        <>Brak znanej daty dostawy</>}
                        </label>
                </div>
                <div className="mb-3">
                    <label for="weight-input" className="form-label">
                    {language==="en" ?
                        <>Gross weight</> :
                        <>Waga brutto</>}
                        </label>
                    <input type="text" onChange={this.handleChange} name="weight" class="form-control" value={weight} id="weight-input"/>
                </div>
                <div className="mb-3">
                    <label for="nettoWeight-input" className="form-label">
                    {language==="en" ?
                        <>Netto weight</> :
                        <>Waga netto</>}
                        </label>
                    <input type="text" onChange={this.handleChange} name="nettoWeight" class="form-control" value={nettoWeight} id="nettoWeight-input"/>
                </div>
                <div className="mb-3">
                    <label for="EAN-input" className="form-label">
                    {language==="en" ?
                        <>EAN Number</> :
                        <>Numer EAN</>}
                        </label>
                    <input type="text" onChange={this.handleChange} name="EAN" class="form-control" value={EAN} id="EAN-input"/>
                </div>
                <div className="mb-3">
                    <label for="boxLength-input" className="form-label">
                    {language==="en" ?
                        <>Box Length</> :
                        <>Długość opakowania</>}
                        </label>
                    <input type="text" onChange={this.handleChange} name="boxLength" class="form-control" value={boxLength} id="boxLength-input"/>
                </div>
                <div className="mb-3">
                    <label for="boxWidth-input" className="form-label">
                    {language==="en" ?
                        <>Box width</> :
                        <>Szerokość opakowania</>}
                        </label>
                    <input type="text" onChange={this.handleChange} name="boxWidth" class="form-control" value={boxWidth} id="boxWidth-input"/>
                </div>
                <div className="mb-3">
                    <label for="boxHeight-input" className="form-label">
                    {language==="en" ?
                        <>Box height</> :
                        <>Wysokość opakowania</>}
                        </label>
                    <input type="text" onChange={this.handleChange} name="boxHeight" class="form-control" value={boxHeight} id="boxHeight-input"/>
                </div>
                <div className="mb-3">
                    <label for="link-input" className="form-label">Link</label>
                    <input onChange={this.handleChange} name="link" class="form-control" value={link} id="link-input"/>
                </div>
                <div className="mb-3">
                    <label for="description-input" className="form-label">
                    {language==="en" ?
                        <>Other</> :
                        <>Uwagi</>}
                        </label>
                    <textarea onChange={this.handleChange} name="description" class="form-control" value={description} id="description-input" rows="3"></textarea>
                </div>
                <div className="mb-3">
                    <label for="pcn-input" className="form-label">PCN</label>
                    <input onChange={this.handleChange} name="PCN" class="form-control" value={PCN} id="pcn-input"/>
                </div>
                <div className="mb-3">
                    <label for="opis-input" className="form-label">
                    {language==="en" ?
                        <>Description</> :
                        <>Opis</>}
                        </label>
                    <textarea onChange={this.handleChange} name="opis" class="form-control" value={opis} id="opis-input" rows="3"></textarea>
                </div>
                    
                {this.state.toEdit ? 
                <><button onClick={this.handleUpdate} type="submit" className="btn btn-primary">
                    {language==="en" ?
                        <>Save</> :
                        <>Zapisz</>}
                    </button>
                    <button onClick={this.handleDelete} className="btn btn-danger">
                    {language==="en" ?
                        <>Delete</> :
                        <>Usuń</>}
                        </button></> : 
                <button onClick={this.handleAdd} type="submit" className="btn btn-success">
                    {language==="en" ?
                        <>Add</> :
                        <>Dodaj</>}
                    </button>}
                {this.state.error}
            </form>
                    {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
</>
        
        )
        return(
        <>{this.state.loaded ? content : <Loader/>}</>
        )
    }
}

const mapStateToProps = (state) => {
    return { machine: state.machine, types: state.types, admin: state.admin, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getMachine, addMachine, updateMachine, deleteMachine, getTypes }
    )(MachineForm);