import React from 'react';
import { connect } from 'react-redux';
import { resetPasswordAdmin, resetPasswordUser, changeLanguage } from '../actions';
import Message from './Message';
import Header from './Header';
import { setCookie } from '../helpers/cookies';
import '../styles/message.css'
import '../styles/index.css';

class ResetPassword extends React.Component{
    constructor(props){
        super(props);
        this.state={
            password: '',
            confirmPassword: '',
            id: this.props.match.params.id,
            token: this.props.match.params.token,
            showMessage: false,
            message: ''
        }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'password':
                this.setState({password: e.target.value});
                break;
            case 'confirmPassword':
                this.setState({confirmPassword: e.target.value});
                break;
            default:
                break;
        }
    }

    changeLanguage = async e => {
        this.props.changeLanguage(e.target.attributes.language.value);
        setCookie('language', e.target.attributes.language.value);
    }

    handleReset = async e => {
        e.preventDefault();
        const { id, password, confirmPassword, token } = this.state;
        if(this.props.match.params.user==='1') {
            try {
                await this.props.resetPasswordAdmin(id, password, confirmPassword, token);
            if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en) }
                    else { this.showMessage(this.props.message.pl)
                    };            
        } 
        catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }            }
        } else {
            try {
                await this.props.resetPasswordUser(id, password, confirmPassword, token);
            if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en) }
                    else { this.showMessage(this.props.message.pl)
                    };            
        } 
        catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }            }
        }
    }

    render() {
        const {language} = this.props.language;
        return(
            <>
            {this.props.match.params.user==='1' ? <Header admin={true} changeLanguage={this.changeLanguage}/> : <Header admin={false} changeLanguage={this.changeLanguage}/>}
            <form>
                <div class="mb-3">
                    <label for="password-input" class="form-label">
                    {language==="en" ?
                    <>Password</> :
                    <>Hasło</>}
                    </label>
                    <input onChange={this.handleChange} type="password" class="form-control" id="password-input" name="password"/>
                </div>
                <div class="mb-3">
                    <label for="confirmPassword-input" class="form-label">
                    {language==="en" ?
                    <>Confirm password</> :
                    <>Potwierdź hasło</>}
                    </label>
                    <input onChange={this.handleChange} type="password" class="form-control" id="confirmPassword-input" name="confirmPassword"/>
                </div>
                <button onClick={this.handleReset} type="submit" class="btn btn-primary">
                {language==="en" ?
                    <>Send</> :
                    <>Wyślij</>}
                </button>
            </form>
                      {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
</>
        )
    }
}

const mapStateToProps = (state) => {
    return { admin: state.admin, user: state.user, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { resetPasswordUser, resetPasswordAdmin, changeLanguage }
    )(ResetPassword);