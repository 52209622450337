import React from 'react';
import { connect } from 'react-redux';
import { getMachines, updateSubscription, getConfig, getTypes, updateActive, updateQuantity } from '../actions'
import { baseURL } from '../apis';
import { getCookie } from '../helpers/cookies';
import { getDate, getFullDate } from '../helpers/date';
import Message from './Message';
import Loader from './Loader';
import TypesList from './TypesList';
import EditInput from './EditInput';


class Machines extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            machines: [],
            error: '',
            allMachines: [],
            details: null,
            error: '',
            showMessage: false,
            message: '',
            types: [],
            loaded: false,
            update: false,
            toEdit: '',
            editValue: ''
        }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {

        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    };

    componentDidUpdate = async () => {
        
            if(this.state.update) {
                const token = getCookie('jwt_access_tkn');
                await this.props.getMachines(token);
                this.setState({allMachines: this.props.machines});
                let machinesView = [];
                this.props.machines.forEach(machine => {
                    this.state.machines.forEach(el => {
                        if(el._id===machine._id) {
                            machinesView.push(machine)
                        };
                    })
                })
                this.setState({machines: machinesView});
                this.setState({update: false})
            }
    }

    componentDidMount = async () => {
        let token;
        if(window.location.href.includes('panel')) {
            token = getCookie('jwt_access_tkn')
        } else {
            token = getCookie('jwt_access')
        }

        if(token) {
            try {

            await this.props.getMachines(token);
            await this.props.getConfig(token);
            await this.props.getTypes(token);
            this.setState({types: this.props.types});
            this.setState({machines: this.props.machines});
            this.setState({allMachines: this.props.machines});
            this.setState({small: this.props.config.small});
            this.setState({medium: this.props.config.medium});
            }catch (err) {
                if(this.props.language.language==="en") {
                    this.setState({error: err.response.data.en})
                } else {
                this.setState({error: err.response.data.pl});
                }
            }
        }

        this.setState({loaded: true});
    }

    handleDetails = e => {
        if(!e.target.attributes.details) {
            return
        }
        if(this.state.details===e.target.attributes.details.value) {
            this.setState({details: null})
        } else {
        this.setState({details: e.target.attributes.details.value})
        }
    }

    handleSort = e => {
        if(!e.target.attributes.subject || !e.target.attributes.sort) {
            return
        }
        let machines = this.state.machines;
        switch(e.target.attributes.subject.value) {
            case 'price':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.marketPrice-b.marketPrice})
                else {
                    machines.sort(function (a,b) { return b.marketPrice-a.marketPrice})
                }
            break;
            case 'model':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function(a,b) { if (a.model<b.model)
                    return -1
                 if (a.model> b.model)
                    return 1
                 return 0})
                else {
                    machines.sort(function(a,b) { if (a.model>b.model)
                        return -1
                     if (a.model<b.model)
                        return 1
                     return 0})
                }
                break;
                case 'type':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function(a,b) { if (a.type<b.type)
                    return -1
                 if (a.type> b.type)
                    return 1
                 return 0})
                else {
                    machines.sort(function(a,b) { if (a.type>b.type)
                        return -1
                     if (a.type<b.type)
                        return 1
                     return 0})
                }
            break;
            case 'quantity':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.quantity-b.quantity})
                else {
                    machines.sort(function (a,b) { return b.quantity-a.quantity})
                }
            break;
            case 'deliveryDate':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.deliveryDate-b.deliveryDate})
                else {
                    machines.sort(function (a,b) { return b.deliveryDate-a.deliveryDate})
                }
            break;
            case 'updateDate':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.updateInfo[a.updateInfo.length-1].date-b.updateInfo[b.updateInfo.length-1].date})
                else {
                    machines.sort(function (a,b) { return b.updateInfo[b.updateInfo.length-1].date-a.updateInfo[a.updateInfo.length-1].date})
                }
            break;
            default:
                break;
        }

        this.setState({machines: machines})

    }

    handleSearch = e => {
        let machines;
        if(e.target.value==='') {
            machines = this.state.allMachines;
        } else {
            machines = this.state.allMachines.filter(el => {
                let regexp = new RegExp(e.target.value, 'i');
                return regexp.test(el.model);
            }
                );
        };

        this.setState({machines: machines})
    }

    handleFilter = e => {
        let machines;
        if(e.target.value==="") {
            machines = this.state.allMachines;
        } else {
            machines = this.state.allMachines.filter(function(element) { return element.type===e.target.value});
        }

        this.setState({machines: machines});

    }

    handleSubscribe = async e => {
        if(!e.target.attributes.subscription) {
            return
        }
        const token = getCookie('jwt_access');
        const userID = getCookie('userID');
        if(token) {
            try {
                await this.props.updateSubscription(e.target.attributes.subscription.value, userID, 'add', token);
                if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en) }
                    else { this.showMessage(this.props.message.pl )};
                this.setState({update: true})
            } catch(err) {
            if(this.props.language.language==="en") {
                        this.showMessage(err.response.data.en, true);
                    } else {
                        this.showMessage(err.response.data.pl, true);
          }            
          }
        }
    }

    handleUnsubscribe = async e => {
        if(!e.target.attributes.subscription) {
            return
        }
        const token = getCookie('jwt_access');
        const userID = getCookie('userID');
        if(token) {
            try {
                await this.props.updateSubscription(e.target.attributes.subscription.value, userID, 'remove', token);
            if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl )};
                      this.setState({update: true})
            } catch(err) {
                if(this.props.language.language==="en") {
                            this.showMessage(err.response.data.en, true);
                        } else {
                            this.showMessage(err.response.data.pl, true);
                        }            
          }
        }
    }

    handleToggleActive = async e => {
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.updateActive(e.target.attributes.machine.value, e.target.attributes.toggle.value, token);
                this.setState({update: true});
            } catch(err) {
            if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }            }
        }
    }

    handleEditQuantity = e => {
        this.setState({toEdit: e.target.attributes.edit.value});
        this.setState({editValue: e.target.innerText});
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'edit-name':
                this.setState({editValue: e.target.value});
                break;
            default:
                break;
        }
    }

    handleCancelQuantity = e => {
        e.preventDefault();
        this.setState({toEdit: ''});
        this.setState({editValue: ''});
    }

    handleUpdateQuantity = async e => {
        e.preventDefault();
        if(isNaN(this.state.editValue) || this.state.editValue < 0) {
            if(this.props.language.language==="en") {
                this.showMessage('Quantity must be a number more or equal 0!', true);
            } else {
                this.showMessage('Stan musi być liczbą większą lub rowną 0!', true);
            }
            return
        }
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.updateQuantity(this.state.toEdit, this.state.editValue, token);
                this.setState({toEdit: ''});
                this.setState({editValue: ''});
                if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en)
                } else {
                    this.showMessage(this.props.message.pl)
                }
                this.setState({update: true})
            } catch(err) {
                if(this.props.language.language==="en") {
                    this.showMessage(err.response.data.en, true)
                } else {
                    this.showMessage(err.response.data.pl, true)
                }
            }
        } else {
            if(this.props.language.language==="en") {
            this.showMessage('Something goes wrong', true)
            }
        else {
            this.showMessage('Coś poszlo nie tak', true)
        }
    }
    }

    renderMachines() {
        const {language} = this.props.language;
        return this.state.machines.map((machine, index) => {
            const machineType = this.state.types.filter( el => { 
                if(el._id === machine.type) { return el.name}
            }) 
            let subscribed = machine.subscribedBy.filter(user => {
                return user._id===this.props.user._id;
            });
            let amount;
            let color;
            if(machine.quantity > this.state.medium) {
                if(language==="en") {
                    amount = 'Big quantity';
                } else {
                    amount = 'Duża ilość';
                }
                color = 'green'
            } else if(machine.quantity > this.state.small) {
                if(language==="en") {
                    amount = 'Medium quantity';
                } else {
                    amount = 'Średnia ilość';
                }
                color='yellow'
            } else if(machine.quantity > 0) {
                if(language==="en") {
                    amount = 'Small quantity';
                } else {
                    amount = 'Mała ilość';
                }
                color='orange'
            } else {
                if(language==="en") {
                    amount = 'Out of stock';
                } else {
                    amount = 'Brak';
                }
                color='red'
            }
            let date;
            let updateDate = getFullDate(new Date(machine.updateInfo[machine.updateInfo.length-1].date));
            if(machine.deliveryDate==0) { 
                if(language==="en") {
                    date='No data' }
                else {
                    date="Brak informacji"
                }
            }
            else {
                date = getDate(new Date(machine.deliveryDate));
            }
            let weight = machine.weight;
            let boxHeight = machine.boxHeight;
            let boxLength = machine.boxLength;
            let boxWidth = machine.boxWidth;
            let EAN = machine.EAN;
            let marketPrice = machine.marketPrice;
            if(machine.weight===0) {
                weight = 'N/A'
            }
            if(machine.EAN==="Not Applicable") {
                EAN = 'N/A'
            }
            if(machine.boxHeight===0) {
                boxHeight = 'N/A'
            }
            if(machine.boxLength===0) {
                boxLength = 'N/A'
            }
            if(machine.boxWidth===0) {
                boxWidth = 'N/A'
            }
            if(machine.marketPrice===0) {
                marketPrice = 'N/A'
            }
            if(index%2===0) {
            return (
                <>
                <tr>
                    <td>{machineType[0].name}</td>
                    <th scope="row">{machine.model}</th>
                    {this.props.admin.login ? 
                    (this.state.toEdit===machine._id ? <td><EditInput value={this.state.editValue} change={this.handleChange} update={this.handleUpdateQuantity} cancel={this.handleCancelQuantity}/></td> : <td edit={machine._id} onClick={this.handleEditQuantity}>{machine.quantity}</td>) : 
                    <td style={{backgroundColor:`${color}`}}>{amount}</td>}
                    <td>{date}</td>
                    {!this.props.admin.login && language==="en" ? <></> : <td>{marketPrice}</td>}
                    <td>{machine.description}</td>
                    <td>{updateDate}</td>
                    {this.props.admin.login ? 
                    <td>
                        <div className="flex">
                        <a className="tooltip-show" href={`${baseURL}/panel/machines/edit/${machine._id}`}><i className="far fa-edit"><span className="tooltip-text">
                            {language==="en" ?
                            <>Edit machine</> :
                            <>Edytuj maszynę</>}
                            </span></i></a>
                            {machine.isActive ? 
                            <a className="tooltip-show">
                                <i onClick={this.handleToggleActive} toggle="false" machine={machine._id} className="fas fa-toggle-on icon-toggle toggle-on">
                                    <span className="tooltip-text">
                                        {language==="en" ?
                                        <>Machine activity</> :
                                        <>Aktywność maszyny</>}
                                    </span>
                                </i>
                            </a> : 
                            <a className="tooltip-show">
                                <i onClick={this.handleToggleActive} toggle="true" machine={machine._id} className="fas fa-toggle-off icon-toggle toggle-off">
                                    <span className="tooltip-text">
                                        {language==="en" ?
                                        <>Machine activity</> :
                                        <>Aktywność maszyny</>}
                                    </span>
                                </i>
                            </a>}                        </div>
                    </td> 
                    : null}
                    <td className=" flex table-more">
                        <div className="tooltip-show">
                            <i onClick={this.handleDetails} details={machine._id}className="fas fa-info-circle">
                                <span className="tooltip-text">
                                    {language==="en" ?
                                    <>Details</> :
                                    <>Zobacz szczegóły</>}
                                    </span>
                            </i>
                        </div>
                        <div className="tooltip-show">
                            {subscribed.length>0 ? 
                            <i subscription={machine._id} onClick={this.handleUnsubscribe} className="fas fa-bell green">
                                <span className="tooltip-text">
                                    {language==="en" ?
                                    <>Unsubscribe</> :
                                    <>Wyłącz subskrypcję</>}
                                </span>
                            </i> : 
                            <i subscription={machine._id} onClick={this.handleSubscribe} className="fas fa-bell">
                                <span className="tooltip-text">
                                    {language==="en" ?
                                    <>Subscribe machine</> :
                                    <>Zapisz się na subskrypcję</>}
                                </span>
                            </i>}
                        </div>
                    </td>
                </tr>
                {this.state.details===machine._id ? <tr className="details-row"><td colspan="9"><ul>
                    <li><b>
                        {language==="en" ?
                        <>EAN Number:</> :
                        <>Numer EAN:</>}
                    </b> {EAN}</li> 
                    <li><b>
                    {language==="en" ?
                        <>Gross weight:</> :
                        <>Waga brutto:</>}
                        </b> {weight} kg</li> 
                    <li><b>
                    {language==="en" ?
                        <>Dimensions (L x W x H):</> :
                        <>Wymiary (dł. x szer. x wys.):</>}
                        </b> {boxLength} cm x {boxWidth} cm x {boxHeight} cm</li> 
                    {machine.link!=='' ? <li><b>Link:</b> <a href={machine.link} target="_blank">CLICK</a></li>:null}
                    </ul></td></tr> : null}
                </>
        )
            } else {
                return (
                    <>
                    <tr className="striped-row">
                        <td>{machineType[0].name}</td>
                        <th scope="row">{machine.model}</th>
                        {this.props.admin.login ? 
                    (this.state.toEdit===machine._id ? <td><EditInput value={this.state.editValue} change={this.handleChange} update={this.handleUpdateQuantity} cancel={this.handleCancelQuantity}/></td> : <td edit={machine._id} onClick={this.handleEditQuantity}>{machine.quantity}</td>) : 
                    <td style={{backgroundColor:`${color}`}}>{amount}</td>}
                        <td>{date}</td>
                        {!this.props.admin.login && language==="en" ? <></> : <td>{marketPrice}</td>}
                        <td>{machine.description}</td>
                        <td>{updateDate}</td>
                        {this.props.admin.login ? 
                        <td>
                            <div className="flex">
                            <a className="tooltip-show" href={`${baseURL}/panel/machines/edit/${machine._id}`}><i className="far fa-edit"><span className="tooltip-text">
                            {language==="en" ?
                            <>Edit machine</> :
                            <>Edytuj maszynę</>}
                                </span></i></a>
                            {machine.isActive ? 
                            <a className="tooltip-show">
                                <i onClick={this.handleToggleActive} toggle="false" machine={machine._id} className="fas fa-toggle-on icon-toggle toggle-on">
                                    <span className="tooltip-text">
                                        {language==="en" ?
                                        <>Machine activity</> :
                                        <>Aktywność maszyny</>}
                                    </span>
                                </i>
                            </a> : 
                            <a className="tooltip-show">
                                <i onClick={this.handleToggleActive} toggle="true" machine={machine._id} className="fas fa-toggle-off icon-toggle toggle-off">
                                    <span className="tooltip-text">
                                        {language==="en" ?
                                        <>Machine activity</> :
                                        <>Aktywność maszyny</>}
                                    </span>
                                </i>
                            </a>}
                            </div>
                        </td> 
                        : null}
                        <td className="flex table-more">
                        <div className="tooltip-show">
                            <i onClick={this.handleDetails} details={machine._id}className="fas fa-info-circle">
                                <span className="tooltip-text">
                                    {language==="en" ?
                                    <>Details</> :
                                    <>Zobacz szczegóły</>}
                                    </span>
                            </i>
                        </div>                            <div className="tooltip-show">
                            {subscribed.length>0 ? 
                            <i subscription={machine._id} onClick={this.handleUnsubscribe} className="fas fa-bell green">
                                <span className="tooltip-text">
                                    {language==="en" ?
                                    <>Unsubscribe</> :
                                    <>Wyłącz subskrypcję</>}
                                </span>
                            </i> : 
                            <i subscription={machine._id} onClick={this.handleSubscribe} className="fas fa-bell">
                                <span className="tooltip-text">
                                    {language==="en" ?
                                    <>Subscribe machine</> :
                                    <>Zapisz się na subskrypcję</>}
                                </span>
                            </i>}
                        </div>
                        </td>
                    </tr>
                    {this.state.details===machine._id ? <tr className="details-row"><td colspan="9"><ul>
                    <li><b>
                        {language==="en" ?
                        <>EAN Number:</> :
                        <>Numer EAN:</>}
                    </b> {EAN}</li> 
                    <li><b>
                    {language==="en" ?
                        <>Gross weight:</> :
                        <>Waga brutto:</>}
                        </b> {weight} kg</li> 
                    <li><b>
                    {language==="en" ?
                        <>Dimensions (L x W x H):</> :
                        <>Wymiary (dł. x szer. x wys.):</>}
                        </b> {boxLength} cm x {boxWidth} cm x {boxHeight} cm</li> 
                    {machine.link!=='' ? <li><b>Link:</b> <a href={machine.link} target="_blank">CLICK</a></li>:null}
                    </ul></td></tr> : null}                      </>
            )
            }
        })

    }

    render() {
        const {language} = this.props.language;
        return (
            <div>{this.state.loaded ? <><table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Type</> :
                    <>Typ</>}
                    </div>
                    <select onChange={this.handleFilter}>
                        {language==="en" ?
                        <option value="">All types</option> :
                        <option value="">Wszystkie typy</option>}
                        <TypesList types={this.state.types}/>
                    </select>
                </th>
                <th>
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Model</> :
                    <>Model</>}
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="model" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="model" className="sort-icon"></img>
                    </div>
                </div>
                {language==="en" ?
                <input
                type="text"
                placeholder="Search model..."
                onChange={this.handleSearch}
                name="model"
                /> :
                <input
                type="text"
                placeholder="Wpisz model..."
                onChange={this.handleSearch}
                name="model"
                />
                }
                </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Quantity</> :
                    <>Stan</>} 
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="quantity" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="quantity" className="sort-icon"></img>
                    </div>
                    </div>
                </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Next delivery date</> :
                    <>Planowana data dostawy</>}
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="deliveryDate" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="deliveryDate" className="sort-icon"></img>
                    </div>
                    </div>
                </th>
                {!this.props.admin.login && language==="en" ? null :<th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Recommended Retail Price</> :
                    <>Sugerowana cena detaliczna</>}
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="price" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="price" className="sort-icon"></img>
                    </div>
                    </div>
                </th>}
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Other description</> :
                    <>Uwagi</>}
                    </div>
                </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>Last update date</> :
                    <>Data ostatniej aktualizacji</>} 
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="updateDate" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="updateDate" className="sort-icon"></img>
                    </div>
                    </div>
                </th>
                {this.props.admin.login ? <th scope="col">
                {language==="en" ?
                    <>Actions</> :
                    <>Akcje</>}
                    </th>: null}
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ?
                    <>More</> :
                    <>Więcej</>}
                    </div>
                    </th>
              </tr>
            </thead>
            <tbody>
                {this.renderMachines()}
            </tbody>
          </table>
          </> : <Loader/>}
          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
          {this.state.error}
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { machines: state.machines, admin: state.admin, machine: state.machine, config: state.config, user: state.user, types: state.types, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getMachines, updateSubscription, getConfig, getTypes, updateActive, updateQuantity }
    )(Machines);