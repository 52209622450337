import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminController from './AdminController';
import UserController from './UserController';
import ResetPassword from './ResetPassword';
import MainSitePl from './MainSitePl';
import MainSiteEn from './MainSiteEn';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={MainSitePl}/>
        <Route path="/en" exact component={MainSiteEn}/>
        <Route path="/machines" exact component={UserController}/>
        <Route path="/machines/*" exact component={UserController}/>
        <Route path="/panel" exact component={AdminController}/>
        <Route path="/panel/*" exact component={AdminController}/>
        <Route path="/password-reset/:user/:id/:token" exact component={ResetPassword}/>
      </Switch>
    </Router>
  );
}

export default App;
