function lz(number) {
    return `${number}`.padStart(2, "0");
}

export function getFullDate(date) {
    return `${date.getFullYear()}-${lz((date.getMonth()+1))}-${lz(date.getDate())} ${lz(date.getHours())}:${lz(date.getMinutes())}:${lz(date.getSeconds())}`
}

 export function getDate(date) {
    return `${lz(date.getDate())}.${lz((date.getMonth()+1))}.${date.getFullYear()}`
}