import { getCookie } from '../helpers/cookies';
export default (
    state = {language: getCookie('language') },
    action) => {
        switch (action.type) {
            case 'CHANGE_LANGUAGE':
                return action.payload;
            default:
                return state;
        }
    }