import react from 'react';
import { useSelector } from 'react-redux';

export default function UserForm({change, submit}) {
    const { language } = useSelector(state => state.language);
    return (
        <form>
            <div className="mb-3">
                <label for="login-input" className="form-label">Login</label>
                <input onChange={change.bind(null)} type="text" className="form-control" id="login-input" name="login"/>
            </div>
            <div className="mb-3">
                <label for="email-input" className="form-label">Email</label>
                <input onChange={change.bind(null)} type="email" className="form-control" id="email-input" name="email"/>
            </div>
            <div className="mb-3">
                <label for="password-input" className="form-label">
                    {language==="en" ?
                    <>Password</> :
                    <>Hasło</>}
                    </label>
                <input onChange={change.bind(null)} type="password" className="form-control" id="password-input" name="password"/>
            </div>
            <div className="mb-3">
                <label for="confirmPassword-input" className="form-label">
                {language==="en" ?
                    <>Confirm password</> :
                    <>Potwierdź hasło</>}
                    </label>
                <input onChange={change.bind(null)} type="password" className="form-control" id="confirmPassword-input" name="confirmPassword"/>
            </div>
            <button onClick={submit.bind(null)} type="submit" className="btn btn-primary">
            {language==="en" ?
                    <>ADD</> :
                    <>Dodaj</>}
                </button>
        </form>
    )
}