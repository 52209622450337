import { combineReducers } from 'redux';
import userReducer from './userReducer';
import adminReducer from './adminReducer';
import machineReducer from './machineReducer';
import fetchMachines from './fetchMachines';
import fetchAdmins from './fetchAdmins';
import fetchUsers from './fetchUsers';
import fetchReports from './fetchReports';
import reportReducer from './reportReducer';
import configReducer from './configReducer';
import fetchTypes from './fetchTypes';
import typeReducer from './typeReducer';
import messageReducer from './messageReducer';
import languageReducer from './languageReducer';
import xmlSchemaReducer from './xmlSchemaReducer';
import fetchXmlSchemas from './fetchXmlSchemas';

export default combineReducers({
    user: userReducer,
    admin: adminReducer,
    machines: fetchMachines,
    machine: machineReducer,
    message: messageReducer,
    users: fetchUsers,
    admins: fetchAdmins,
    reports: fetchReports,
    report: reportReducer,
    config: configReducer,
    types: fetchTypes,
    type: typeReducer,
    language: languageReducer,
    schemas: fetchXmlSchemas,
    schema: xmlSchemaReducer
});