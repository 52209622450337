import React from 'react';

function Attribute({idAttribute, nameName, idName, nameValue, idValue, language, selectValue, handleChange}) {
    return(
        <div id={idAttribute} className="row">
            <div className="col-sm-3">
                <label for={idName} className="form-label">
                            {language==="en" ?
                            <>Attribute name</> :
                            <>Nazwa atrybutu</>}
                </label>
                <input onChange={handleChange.bind()}type="text" name={nameName} className="form-control" id={idName}/>
            </div>
            <div className="col-sm-3">
                <label for={idValue} className="form-label">
                            {language==="en" ?
                            <>Attribute value</> :
                            <>Wartość atrybutu</>}
                </label>
                <select id={idValue} name={nameValue} onChange={handleChange.bind()}>
                    <option value="">Wybierz z listy</option>
                    <option value="date-YYYY-MM-DD">Data wygenerowania (YYYY-MM-DD)</option>
                    <option value="date-DD-MM-YYYY">Data wygenerowania (DD-MM-YYYY)</option>
                    <option value="date-HH:MM:SS-DD-MM-YYYY">Data wygenerowania (HH:MM:SS DD-MM-YYYY)</option>
                    <option value="date-HH:MM:SS-YYYY-MM-DD">Data wygenerowania (HH:MM:SS YYYY-MM-DD)</option>
                    <option value="text">Dowolny tekst</option>
                </select>
            </div>
            {selectValue==="text" ? <div className="col-sm-3">
                <label>Wpisz wartość</label>
                <input></input>
            </div> : null}
        </div>
    )
}

export default Attribute;