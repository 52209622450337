import axios from 'axios';
export default axios.create({
    // baseURL: 'http://localhost:3000',
    baseURL:'https://server.usermd.net',
    headers: {"Access-Control-Allow-Origin": "*"}

});
// export const baseURL='http://localhost:8000'
export const baseURL='https://revorm.eu'

// export const serverbaseURL='http://localhost:3000'
export const serverbaseURL='https://server.usermd.net'