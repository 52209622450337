import React from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getMachines, updateSortID } from '../actions/index';
import { getCookie } from '../helpers/cookies';
import Message from './Message';
import Loader from './Loader';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

class SortEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            loaded: false,
            message: '',
            showMessage: false,
            isError: false,
            update: false,
            machines: []
        };
        this.onDragEnd = this.onDragEnd.bind(this);

    }

    async onDragEnd(result) {
        if (!result.destination) {
          return;
        }
    
        const machines = reorder(
          this.state.machines,
          result.source.index,
          result.destination.index
        );
    
        await this.setState({
          machines: machines
        });
        this.handleSortEdit();
        console.log(this.state.machines)
      }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');

        if(token) {
            try {
                await this.props.getMachines(token);
                this.setState({machines: this.props.machines});
            } catch(err) {
                if(err.response.data) {
                    if(this.props.language.language==="en") {
                        this.setState({error: err.response.data.en});
                    } else {
                    this.setState({error: err.response.data.pl})
                    }
                    }            
            }
        }

        this.setState({loaded: true});
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    handleSortEdit = async e => {
        const token = getCookie('jwt_access_tkn');
        this.setState({error: ''})
        console.log(this.state.machines)

        try {
            await this.props.updateSortID(this.state.machines, token);
            if(this.props.language.language==="en") {
                this.showMessage(this.props.message.en) }
            else { this.showMessage(this.props.message.pl)
                }; 
        } catch(err) {
            if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        }
    } 

    render() {
        const { language } = this.props.language;
        return(
            <div>
          {this.state.loaded ? 
          <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                      <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      >
                          {this.props.language.language==="en" ? <div className="drag-header">Products order</div> : <div className="drag-header">Kolejność produktów</div>}
                          {this.props.language.language==="en" ? <p>* Drag elements up or down to sort</p> : <p>* Przeciągaj elementy w górę i w dół, żeby posortować</p>}
                          {this.state.machines.map((item,index) => (
                              <Draggable key={item._id} draggableId={item._id} index={index}>
                                  {(provided, snapshot) => (
                                      <div
                                      className="drag-element"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      >
                                          {item.model}
                                      </div>
                                  )}
                              </Draggable>
                          ))}
                          {provided.placeholder}
                      </div>
                  )}
              </Droppable>
          </DragDropContext> :
          <Loader />}

          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
          {this.state.error}
        </div>
          
        )
    }

}

const mapStateToProps = (state) => {
    return { machines: state.machines, machine: state.machine, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getMachines, updateSortID }
    )(SortEdit);

