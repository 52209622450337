import apis from '../apis/index';
import { setCookie } from '../helpers/cookies';

export const getUser = (id, token) => async dispatch => {
    let response = await apis.get(`user/${id}`, {headers: {token: token}});
    dispatch({type: 'GET_USER', payload: response.data});
};

export const getUsers = (token) => async dispatch => {
    let response = await apis.get('user', {headers: {token: token}});
    dispatch({type: 'GET_USERS', payload: response.data});
}

export const addUser = (token, login, email, password, confirmPassword) => async dispatch => {
    let response = await apis.post('user/add', {login, email, password, confirmPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const userSignIn = (login, password) => async dispatch => {
    let response = await apis.post('user/signin', {login, password});
    setCookie('userID', response.data._id, 240);
    setCookie('jwt_access', response.data.token, 240);
    dispatch({type: 'USER_SIGNIN', payload: response.data});
};

export const updateUser = (id, email, currentPassword, token) => async dispatch => {
    let response = await apis.put(`user/update/data/${id}`, {email, currentPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};


export const updatePasswordUser = (id, currentPassword, password, confirmPassword, token) => async dispatch => {
    let response = await apis.put(`user/update/password/${id}`, {currentPassword, password, confirmPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const passwordReminderUser = (email) => async dispatch => {
    let response = await apis.post('user/passwordreminder', {email});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const resetPasswordUser = (id, password, confirmPassword, token) => async dispatch => {
    let response = await apis.put(`user/resetpassword/${id}`, {password, confirmPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const userSignOut = () => async dispatch => {
    setCookie('userID', "", 0.00001);
    setCookie('jwt_access', "", 0.00001);
    dispatch({type: 'USER_SIGNOUT', payload: {}});
}

export const deleteUser = (id, token) => async dispatch => {
    let response = await apis.delete(`user/${id}`, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const getAdmin = (id, token) => async dispatch => {
    let response = await apis.get(`admin/${id}`, {headers: {token: token}});
    dispatch({type: 'GET_ADMIN', payload: response.data});
};

export const getAdmins = (token) => async dispatch => {
    let response = await apis.get('admin', {headers: {token: token}});
    dispatch({type: 'GET_ADMINS', payload: response.data});
}

export const addAdmin = (token, login, email, password, confirmPassword) => async dispatch => {
    let response = await apis.post('admin/add', {login, email, password, confirmPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const adminSignIn = (login, password) => async dispatch => {
    let response = await apis.post('admin/signin', {login, password});
    setCookie('adminID', response.data._id, 240);
    setCookie('jwt_access_tkn', response.data.token, 240);
    dispatch({type: 'ADMIN_SIGNIN', payload: response.data});
};

export const updateAdmin = (id, login, email, currentPassword, token) => async dispatch => {
    let response = await apis.put(`admin/update/data/${id}`, {login, email, currentPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};


export const updatePasswordAdmin = (id, currentPassword, password, confirmPassword, token) => async dispatch => {
    let response = await apis.put(`admin/update/password/${id}`, {currentPassword, password, confirmPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const passwordReminderAdmin = (email) => async dispatch => {
    let response = await apis.post('admin/passwordreminder', {email});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const resetPasswordAdmin = (id, password, confirmPassword, token) => async dispatch => {
    let response = await apis.put(`admin/resetpassword/${id}`, {password, confirmPassword}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const adminSignOut = () => async dispatch => {
    setCookie('adminID', "", 0.00001);
    setCookie('jwt_access_tkn', "", 0.00001);
    dispatch({type: 'ADMIN_SIGNOUT', payload: {}});
}

export const deleteAdmin = (id, token) => async dispatch => {
    let response = await apis.delete(`admin/${id}`, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const getMachines = (token) => async dispatch => {
    let response = await apis.get('machine', {headers: {token: token}});
    dispatch({type: 'GET_MACHINES', payload: response.data});
}

export const getMachine = (machineId, token) => async dispatch => {
    let response = await apis.get(`machine/id/${machineId}`, {headers: {token: token}});
    dispatch({type: 'GET_MACHINE', payload: response.data});
}

export const addMachine = (brand, model, type, quantity, marketPrice, deliveryDate, description, EAN, weight, nettoWeight, boxLength, boxWidth, boxHeight, link, PCN, opis, token) => async dispatch => {
    let response = await apis.post('machine/add', {brand, model, type, quantity, marketPrice, deliveryDate, description, EAN, weight, nettoWeight, boxLength, boxWidth, boxHeight, link, PCN, opis}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const updateMachine = (machineId, brand, model, type, quantity, marketPrice, deliveryDate, description, EAN, weight, nettoWeight, boxLength, boxWidth, boxHeight, link, PCN, opis, token) => async dispatch => {
    let response = await apis.put(`machine/id/${machineId}`, {brand, model, type, quantity, marketPrice, deliveryDate, description, EAN, weight, nettoWeight, boxLength, boxWidth, boxHeight, link, PCN, opis}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const updateSortID = (machines, token) => async dispatch => {
    let response = await apis.put(`machine/sort`, {machines}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const updateActive = (machineId, action, token) => async dispatch => {
    let response = await apis.put(`machine/active/${machineId}`, {action}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const updateQuantity = (machineId, quantity, token) => async dispatch => {
    let response = await apis.put(`machine/update/quantity/${machineId}`, {quantity}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const getRaports = (token) => async dispatch => {
    let response = await apis.get('machine/raport', {headers: {token: token}});
    dispatch({type: 'GET_RAPORTS', payload: response.data});
}

export const deleteMachine = (machineId, token) => async dispatch => {
    let response = await apis.delete(`machine/${machineId}`, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const updateSubscription = (machineId, userId, action, token) => async dispatch => {
    let response = await apis.put(`machine/subscription/${machineId}/user/${userId}`, {action}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};

export const getReportsNames = (token) => async dispatch => {
    let response = await apis.get('report', {headers: {token: token}});
    dispatch({type: 'GET_REPORTS_NAMES', payload: response.data});
};

export const getReport = (id, token) => async dispatch => {
    let response = await apis.get(`report/${id}`, {headers: {token: token}});
    dispatch({type: 'GET_REPORT', payload: response.data});
}

export const createReport = (machines, name, reportSchema, token) => async dispatch => {
    let response = await apis.post('report/create', {machines, name, reportSchema}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};

export const updateReport = (id, machines, reportSchema, token) => async dispatch => {
    let response = await apis.put(`report/${id}`, {machines, reportSchema}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};

export const deleteReport = (id, token) => async dispatch => {
    let response = await apis.delete(`report/${id}`, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};

export const getConfig = (token) => async dispatch => {
    let response = await apis.get('config/get', {headers: {token: token}});
    dispatch({type: 'GET_CONFIG', payload: response.data});
};

export const updateConfig = (small, medium, token) => async dispatch => {
    let response = await apis.put('config/update', {small, medium}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const getTypes = (token) => async dispatch => {
    let response = await apis.get('type', {headers: {token: token}});
    dispatch({type: 'GET_TYPES', payload: response.data});
}

export const getType = (id, token) => async dispatch => {
    let response = await apis.get(`type/${id}`, {headers: {token: token}});
    dispatch({type: 'GET_TYPE', payload: response.data});
}

export const createType = (name, token) => async dispatch => {
    let response = await apis.post('type/create', {name}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const updateType = (id, name, token) => async dispatch => {
    let response = await apis.put(`type/${id}`, {name}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const deleteType = (id, token) => async dispatch => {
    let response = await apis.delete(`type/${id}`, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
}

export const changeLanguage = (language) => dispatch => {
    dispatch({type: 'CHANGE_LANGUAGE', payload: {language: language}});
}

export const getXmlSchemas = (token) => async dispatch => {
    let response = await apis.get('xmlschema', {headers: {token: token}});
    dispatch({type: 'GET_SCHEMAS', payload: response.data});
};

export const getXmlSchema = (id, token) => async dispatch => {
    let response = await apis.get(`xmlschema/${id}`, {headers: {token: token}});
    dispatch({type: 'GET_SCHEMA', payload: response.data});
}

export const createXmlSchema = (name, document, product, token) => async dispatch => {
    let response = await apis.post('xmlschema/create', {name, document, product}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};

export const updateXmlSchema = (id, name, document, product, token) => async dispatch => {
    let response = await apis.put(`xmlschema/${id}`, {name, document, product}, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};

export const deleteXmlSchema = (id, token) => async dispatch => {
    let response = await apis.delete(`xmlschema/${id}`, {headers: {token: token}});
    dispatch({type: 'MESSAGE', payload: response.data});
};
