import React from 'react';

function TypesList({types, currentType}) {
        return types.map(type => {
            if(currentType && type._id===currentType) {
            return(
                <option selected value={type._id}>{type.name}</option>
            )}
            else {
                return (
                    <option value={type._id}>{type.name}</option>
                )
            }
            })
    
};

export default TypesList;