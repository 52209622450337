import React from 'react';

export default function EditInput ({value, change, update, cancel}) {
    return(
        <form>
            <input onChange={change.bind(null)} type="text" className="form-control" name="edit-name" value={value} />
            <button onClick={update.bind(null)} className="btn btn-primary">ZAPISZ</button>
            <button onClick={cancel.bind(null)} className="btn btn-danger">ANULUJ</button>
        </form>
    )
}