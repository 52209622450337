export default (
    state = {},
    action) => {
        switch (action.type) {
            case 'GET_ADMIN':
                return action.payload;
            case 'ADMIN_SIGNIN':
                return action.payload;
            case 'ADMIN_SIGNOUT':
                return action.payload;
            default:
                return state;
        }
    }