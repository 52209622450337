import React from 'react';
import { connect } from 'react-redux';
import Admins from './Admins';
import Users from './Users';
import Config from './Config';
import Types from './Types';
import SortEdit from './SortEdit';
import { getCookie } from '../helpers/cookies';

class Administration extends React.Component {
    constructor(props) {
        super(props);        
    }

    handleNav = e => {
        let navs = Array.from(document.querySelectorAll('.nav-link'))
        navs.forEach(el => {
            el.classList.remove('active');
        })
        e.target.classList.add('active');
        let tabs = Array.from(document.querySelectorAll('.tab-pane'));
        tabs.forEach(el => {
            el.classList.remove('show', 'active');
        })
        let tab = document.querySelector(e.target.attributes.databstarget.value);
        tab.classList.add('show', 'active');
    }

    render() {
        const {language} = this.props.language;
        return(<>
            <nav>
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button onClick={this.handleNav} className="nav-link active" id="nav-admin-tab" data-bs-toggle="tab" databstarget="#nav-admin" type="button" role="tab" aria-controls="nav-admin" aria-selected="true">
        {language==="en" ?
            <>Admins</> :
            <>Administratorzy</>}
    </button>
    <button onClick={this.handleNav} className="nav-link" id="nav-user-tab" data-bs-toggle="tab" databstarget="#nav-user" type="button" role="tab" aria-controls="nav-user" aria-selected="false">
    {language==="en" ?
            <>Users</> :
            <>Użytkownicy</>}
        </button>
    <button onClick={this.handleNav} className="nav-link" id="nav-config-tab" data-bs-toggle="tab" databstarget="#nav-config" type="button" role="tab" aria-controls="nav-config" aria-selected="false">
    {language==="en" ?
            <>Quantity showing</> :
            <>Wyświetlanie ilości</>}
        </button>
    <button onClick={this.handleNav} className="nav-link" id="nav-type-tab" data-bs-toggle="tab" databstarget="#nav-type" type="button" role="tab" aria-controls="nav-type" aria-selected="false">
    {language==="en" ?
            <>Types</> :
            <>Typy</>}
        </button>
    <button onClick={this.handleNav} className="nav-link" id="nav-sort-tab" data-bs-toggle="tab" databstarget="#nav-sort" type="button" role="tab" aria-controls="nav-sort" aria-selected="false">
    {language==="en" ?
            <>Sort</> :
            <>Sortuj</>}
        </button>
  </div>
</nav>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="nav-admin" role="tabpanel" aria-labelledby="home-tab"><Admins /></div>
  <div className="tab-pane fade" id="nav-user" role="tabpanel" aria-labelledby="profile-tab"><Users /></div>
  <div className="tab-pane fade" id="nav-config" role="tabpanel" aria-labelledby="profile-tab"><Config /></div>
  <div className="tab-pane fade" id="nav-type" role="tabpanel" aria-labelledby="profile-tab"><Types /></div>
  <div className="tab-pane fade" id="nav-sort" role="tabpanel" aria-labelledby="profile-tab"><SortEdit /></div>
</div></>

        )
    }
}

const mapStateToProps = (state) => {
    return { admin: state.admin, language: state.language };

};

export default connect(
    mapStateToProps,
    {  }
    )(Administration);