import React from 'react';
import { connect } from 'react-redux';
import { getConfig, updateConfig } from '../actions';
import { getCookie } from '../helpers/cookies';
import Message from './Message';


class Config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            small: '',
            medium: '',
            message: '',
            showMessage: false,
            update: false
        }
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.getConfig(token);
                this.setState({small: this.props.config.small});
                this.setState({medium: this.props.config.medium});
            } catch(err) {
            }
        }
    }

    componentDidUpdate = async () => {
        if(this.state.update) {
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.getConfig(token);
                this.setState({small: this.props.config.small});
                this.setState({medium: this.props.config.medium});
            } catch(err) {
            }
        }
        this.setState({update: false});
    }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    handleUpdate = async e=> {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.updateConfig(this.state.small, this.state.medium, token);
            if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en) }
                    else { this.showMessage(this.props.message.pl )};            
      } 
      catch(err) {
            if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }
          this.setState({update: true});                  
        }
        }
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'small':
                this.setState({small: e.target.value});
            break;
            case 'medium':
                this.setState({medium: e.target.value});
                break;
            default:
                break;
        }
    }

    render() {
        const {language} = this.props.language;
        return(
            <div>
                <form>
            <div className="config-container">
                <label for="small-input" className="pr-2 form-label">
                {language==="en" ?
                    <>Small quantity: </> :
                    <>Mała ilość: </>}
                     </label>
                <input className="mr-2 form-control input-config" disabled value="1"/><div>-</div><input onChange={this.handleChange} value={this.state.small} type="text" className="ml-2 input-config form-control" id="small-input" name="small"/>
            </div>
            <div className="config-container">
                <label for="medium-input" className="pr-2 form-label">
                {language==="en" ?
                    <>Medium quantity: </> :
                    <>Średnia ilość: </>} 
                    </label>
                <input className="mr-2 form-control input-config" disabled value={Number(this.state.small)+1}/><div>-</div><input onChange={this.handleChange} value={this.state.medium} type="text" className="ml-2 input-config form-control" id="medium-input" name="medium"/>
            </div>
            <div className="config-container">
                <label for="password-input" className="pr-2 form-label">
                {language==="en" ?
                    <>Big quantity: </> :
                    <>Duża ilość: </>} 
                    </label>
                <input className="mr-2 form-control input-config" disabled value={Number(this.state.medium)+1+'<'}/>
            </div>
            <button onClick={this.handleUpdate} type="submit" className="btn btn-primary">
            {language==="en" ?
                    <>Save</> :
                    <>Zapisz</>}
                </button>
        </form>
        {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { config: state.config, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getConfig, updateConfig }
    )(Config);

