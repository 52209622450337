import React from 'react';
import { useSelector } from 'react-redux';

export default function Reminder({change, submit}) {
  const {language} = useSelector(state => state.language);

    return (
        <form>
  <div className="mb-3">
    <label for="exampleInputEmail1" className="form-label">Email</label>
    <input onChange={change.bind(null)} type="email" className="form-control" name="email-reminder" id="exampleInputEmail1" aria-describedby="emailHelp" required/>
    <div id="emailHelp" className="form-text">
      {language==="en" ?
      <>Type your email.</> :
      <>Wpisz adres email przypisany do konta.</>}
      </div>
  </div>
  <button onClick={submit.bind(null)} type="submit" className="btn btn-primary">
    {language==="en" ?
    <>Send</> :
    <>Wyślij</>}</button>
</form>
    )
}