import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { baseURL } from '../apis';
import { getCookie, setCookie } from '../helpers/cookies';
import { getAdmin, adminSignIn, updateAdmin, updatePasswordAdmin, adminSignOut, passwordReminderAdmin, adminChangeLanguage, changeLanguage } from '../actions';
import Header from './Header';
import SignIn from './SignIn';
import Message from './Message';
import Machines from './Machines';
import MachineForm from './MachineForm';
import AccountAdmin from './AccountAdmin';
import Loader from './Loader';
import Administration from './Administration';
import Reminder from './Reminder';
import ReportsView from './ReportsView';
import Reports from './Reports';
import SchemasView from './SchemasView';
import SchemaForm from './SchemaForm';
import '../styles/message.css'
import '../styles/index.css';


class AdminController extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            adminLogin: '',
            isSigned: false,
            login: '',
            password: '',
            error: '',
            showMessage: false,
            message: '',
            loaded: false,
            emailReminder: '',
            update: false,
            loading: false
        }
    }

    tokenError() {
        setCookie('adminID', "", 0.00001);
        setCookie('jwt_access_tkn', "", 0.00001);
        return window.location.replace(`${baseURL}/panel`);
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    componentDidUpdate = async () => {
        console.log(this.props.language.language)
        if(this.state.update) {
            const token = getCookie('jwt_access_tkn');
            const id = getCookie('adminID');
            if(token) {
                try {
                    await this.props.getAdmin(id, token);
                    this.setState({adminLogin: this.props.admin.login});
                    this.setState({isSigned: true});
                    this.setState({language: this.props.admin.language})
                } catch(err) {
                    return this.tokenError();
                };
            } else {
                if(window.location.href!==`${baseURL}/panel` && window.location.href!==`${baseURL}/panel/reminder`) {
                    window.location.replace(`${baseURL}/panel`)
                }
            }
            this.setState({update: false})
        }
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'login':
                this.setState({login: e.target.value});
                break;
            case 'password':
                this.setState({password: e.target.value});
                break;
            case 'email-reminder':
                this.setState({emailReminder: e.target.value});
            default:
                break;
        }
    }

    handleSignIn = async e => {
        e.preventDefault();

        if(this.state.login==='' || this.state.password==='') {
            if(this.props.language.language==="en") {
                return this.showMessage('Login and password cannot be empty!', true)
            } else {
                return this.showMessage('Login i hasło nie moga być puste!', true)
            }
        }
        try {
            this.setState({loading: true});
            await this.props.adminSignIn(this.state.login, this.state.password)
            window.location.replace(`${baseURL}/panel/machines`)
        } catch(err) {
            if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          } 
        }
        this.setState({loading: false});
    }

    handleSignOut = async e => {
        e.preventDefault();
        await this.props.adminSignOut()
        window.location.replace(`${baseURL}/panel`)
    }

    handleReminder = async e => {
        e.preventDefault();
        try {
        await this.props.passwordReminderAdmin(this.state.emailReminder);
        if(this.props.language.language==="en") {
            this.showMessage(this.props.message.en) }
            else { this.showMessage(this.props.message.pl )}        
            } 
        catch(err) {
        if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en);
          } else {
            this.showMessage(err.response.data.pl);
          }         
        }

    }

    changeLanguage = async e => {
        this.props.changeLanguage(e.target.attributes.language.value);
        setCookie('language', e.target.attributes.language.value);
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        const id = getCookie('adminID');
        if(token) {
            try {
                await this.props.getAdmin(id, token);
                this.setState({adminLogin: this.props.admin.login});
                this.setState({isSigned: true});
                this.setState({language: this.props.admin.language})
                if(window.location.href===`${baseURL}/panel`) {
                    window.location.replace(`${baseURL}/panel/machines`)
                }
            } catch(err) {
                return this.tokenError();
            };
        } else {
            if(window.location.href!==`${baseURL}/panel` && window.location.href!==`${baseURL}/panel/reminder`) {
                window.location.replace(`${baseURL}/panel`)
            }
        }
        this.setState({loaded: true})
    }

    render() {
        return(
            <>{this.state.loaded ?
            <><Header admin={true} isSigned={this.state.isSigned} login={this.state.adminLogin} signOut={this.handleSignOut} changeLanguage={this.changeLanguage}/>
            <Router>
                <Switch>
                    <Route path="/panel/" exact>
                        <SignIn change={this.handleChange} signin={this.handleSignIn} loading={this.state.loading}/>
                    </Route>
                    <Route path="/panel/machines" exact>
                        <Machines/>
                    </Route>
                    <Route path="/panel/reports" exact component={ReportsView}/>
                    <Route path="/panel/reports/new" exact component={Reports}/>
                    <Route path="/panel/reports/:reportId" exact component={Reports}/>
                    {/* <Route path="/panel/schemas/" exact component={SchemasView}/>
                    <Route path="/panel/schemas/add" exact component={SchemaForm}/>
                    <Route path="/panel/schemas/edit/:xmlSchemaId" exact component={SchemaForm}/> */}
                    <Route path="/panel/administration" exact>
                        <Administration/>
                    </Route>
                    <Route path="/panel/machines/add" exact>
                        <MachineForm />
                    </Route>
                    <Route path="/panel/machines/edit/:machineId" exact component={MachineForm}/>
                    <Route path="/panel/account" exact>
                        <AccountAdmin admin={this.props.admin}/>
                    </Route>
                    <Route path="/panel/reminder" exact>
                        <Reminder change={this.handleChange} submit={this.handleReminder}/>
                    </Route>
                    
                </Switch>
            </Router>
            {this.state.error}
            {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}</> : <Loader />}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return { admin: state.admin, language: state.language, message: state.message};

};

export default connect(
    mapStateToProps,
    { getAdmin, adminSignIn, updateAdmin, updatePasswordAdmin, adminSignOut, passwordReminderAdmin, changeLanguage }
    )(AdminController);