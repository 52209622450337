import React from 'react';
import { connect } from 'react-redux';
import { getAdmins, deleteAdmin, addAdmin } from '../actions';
import { getCookie } from '../helpers/cookies';
import Loader from './Loader';
import Message from './Message';
import UserForm from './UserForm';

class Admins extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            error: '',
            admins: [],
            showMessage: false,
            message: '',
            add: false,
            login: '',
            email: '',
            password: '',
            confirmPassword: '',
            update: false
        }
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        try {
            await this.props.getAdmins(token)
            this.setState({admins: this.props.admins})
        } catch(err) {
            if(this.props.language.language==="en") {
            this.setState({error: err.response.data.en});
          } else {
                this.setState({error: err.response.data.pl})          
        }
            
        }
        this.setState({loaded: true})
    }

    componentDidUpdate = async () => {
        if(this.state.update) {
            const token = getCookie('jwt_access_tkn');
            try {
                await this.props.getAdmins(token);
                this.setState({admins: this.props.admins});
                this.setState({error: ''});
            }
            catch(err) {
                this.setState({admins: []});
                this.setState({error: err.response.data.en});
            }
            
            this.setState({update: false})
        }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    handleDelete = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        try {
        await this.props.deleteAdmin(e.target.id, token);
if(this.props.language.language==="en") {
    this.showMessage(this.props.message.en) }
    else { this.showMessage(this.props.message.pl )}       
     this.setState({update: true});
        }
        catch(err) {
        if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }           }

    }

    renderAdmins = () => {
        return this.state.admins.map(admin => {
            return (
            <tr>
                <th scope="row">{admin.login}</th>
                <td>{admin.email}</td>
                <td><i id={admin._id} onClick={this.handleDelete} className="fas fa-trash"></i></td>
            </tr>
        )
        })
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'login':
                this.setState({login: e.target.value});
                break;
            case 'email':
                this.setState({email: e.target.value});
                break;
            case 'password':
                this.setState({password: e.target.value});
                break;
            case 'confirmPassword':
                this.setState({confirmPassword: e.target.value});
                break;
            default:
                break;
            
        }
    }

    handleAdd = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        const { login, email, password, confirmPassword } = this.state;
        try {
            await this.props.addAdmin(token, login, email, password, confirmPassword);
            if(this.props.language.language==="en") {
                this.showMessage(this.props.message.en) }
                else { this.showMessage(this.props.message.pl )}            
            this.setState({login:''});
            this.setState({email:''});
            this.setState({password:''});
            this.setState({confirmPassword:''});
            let inputs = Array.from(document.querySelectorAll('.form-control'));
            inputs.forEach(el => {
                el.value=''
            })
            this.setState({update: true})

        } catch(err) {
            if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }                   }
    }

    handleForm = () => {
        if(this.state.add) {
            this.setState({add: false})
        } else {
            this.setState({add:true})
        }
    }

    render() {
        const {language} = this.props.language;
        return(<>{this.state.loaded ? 
            <div>
            <button onClick={this.handleForm} className="btn btn-primary">
            {language==="en" ?
            <>Add admin</> :
            <>Dodaj admina</>}
                </button>
            {this.state.add ? <UserForm change={this.handleChange} submit={this.handleAdd} /> : null}
            <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">Login</th>
                <th scope="col">Email</th>
                <th scope="col">
                    {language==="en" ?
                    <>Actions</> :
                    <>Akcje</>}
                    </th>
              </tr>
            </thead>
            <tbody>
                {this.renderAdmins()}
            </tbody>
          </table></div> : <Loader />}
          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
</>
        )
    }
}

const mapStateToProps = (state) => {
    return { admins: state.admins, admin: state.admin, message: state.message, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getAdmins, deleteAdmin, addAdmin }
    )(Admins);