import React from 'react';
import { useSelector } from 'react-redux';

export default function RaportName({error, name, handleName, handleSchema, closeRaport, createRaport}) {
    const { language } = useSelector(state => state.language);
    return(
        <div className="raport-container">
            <div className="raport-header">
                {language==="en" ?
                <>Create report</> :
                <>Tworzenie raportu</>}
            <button className="btn btn-danger" onClick={closeRaport.bind(null)}>X</button></div>
            <div className="raport-content">
            <div className="mb-3">
                    <label for="raport-select" className="form-label">
                    {language==="en" ?
                    <>Select schema</> :
                    <>Wybierz schemat</>
                    }</label>
                    <select style={{marginLeft: "5px"}}id="raport-select" name="raport-schema" onChange={handleSchema.bind(null)}>
                        <option value="">Wybierz z listy</option>
                        <option value="classic">Klasyczny</option>
                        <option value="terg">Terg</option>
                    </select>
            </div>
            <div className="mb-3">
                    <label for="raport-input" className="form-label">
                    {language==="en" ?
                    <>Report name</> :
                    <>Nazwa raportu</>
                    }</label>
                    <input type="text" onChange={handleName.bind(null)}name="raport-name" className="form-control" id="raport-input"/>
            </div>
            <button onClick={createRaport.bind(null)} className="btn btn-success">
                {language==="en" ?
                <>SAVE</> :
                <>ZAPISZ</>}
                </button>
            {error}
            </div>
        </div>
    )
}