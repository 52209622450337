import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { baseURL } from '../apis';
import { getCookie, setCookie } from '../helpers/cookies';
import { getUser, userSignIn, updateUser, updatePasswordUser, userSignOut, passwordReminderUser, changeLanguage } from '../actions';
import Header from './Header';
import SignIn from './SignIn';
import Message from './Message';
import Machines from './Machines';
import Loader from './Loader';
import AccountUser from './AccountUser';
import Reminder from './Reminder';
import '../styles/message.css'
import '../styles/index.css';


class UserController extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            userLogin: '',
            isSigned: false,
            login: '',
            password: '',
            error: '',
            showMessage: false,
            message: '',
            loaded: false,
            emailReminder: '',
            loading: false
        }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    handleChange = e => {
        this.setState({error: ''})
        switch(e.target.name) {
            case 'login':
                this.setState({login: e.target.value});
                break;
            case 'password':
                this.setState({password: e.target.value});
                break;
            case 'email-reminder':
                this.setState({emailReminder: e.target.value});
                break;
            default:
                break;
        }
    }

    handleReminder = async e => {
        e.preventDefault();
        try {
        await this.props.passwordReminderUser(this.state.emailReminder);
if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl)
        };        } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en);
          } else {
            this.showMessage(err.response.data.pl);
          }        }

    }

    handleSignIn = async e => {
        e.preventDefault();
        if(this.state.login==='' || this.state.password==='') {
            if(this.props.language.language==="en") {
                return this.showMessage('Login and password cannot be empty!', true)
            } else {
                return this.showMessage('Login i hasło nie moga być puste!', true)
            }
        }
        try {
            this.setState({loading: true});
            await this.props.userSignIn(this.state.login, this.state.password)
            window.location.replace(`${baseURL}/machines/show`)
        } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        }
          this.setState({loading: false});
    }

    handleSignOut = async e => {
        e.preventDefault();
        await this.props.userSignOut()
        window.location.replace(`${baseURL}/machines`)
    }

    tokenError() {
        setCookie('adminID', "", 0.00001);
        setCookie('jwt_access', "", 0.00001);
        return window.location.replace(`${baseURL}/machines`);
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access');
        const id = getCookie('userID');
        if(token) {
            try {
                await this.props.getUser(id, token);
                this.setState({userLogin: this.props.user.login});
                this.setState({isSigned: true});
                if(window.location.href===`${baseURL}/machines`) {
                    window.location.replace(`${baseURL}/machines/show`)
                }
            } catch(err) {
                return this.tokenError();
            };
        } else {
            if(window.location.href!==`${baseURL}/machines` && window.location.href!==`${baseURL}/machines/reminder`) {
                window.location.replace(`${baseURL}/machines`)
            }
        }
        this.setState({loaded: true})
    }

    changeLanguage = async e => {
        this.props.changeLanguage(e.target.attributes.language.value);
        setCookie('language', e.target.attributes.language.value);
    }

    render() {
        
        return(
            <>{this.state.loaded ?
            <><Header admin={false} isSigned={this.state.isSigned} login={this.state.userLogin} signOut={this.handleSignOut} changeLanguage={this.changeLanguage}/>
            <Router>
                <Switch>
                    <Route path="/machines" exact>
                        <SignIn change={this.handleChange} signin={this.handleSignIn} loading={this.state.loading}/>
                    </Route>
                    <Route path="/machines/show" exact>
                        <Machines />
                    </Route>
                    <Route path="/machines/account" exact>
                        <AccountUser user={this.props.user}/>
                    </Route>
                    <Route path="/machines/reminder" exact>
                        <Reminder change={this.handleChange} submit={this.handleReminder} />
                    </Route>
                </Switch>
            </Router>
            {this.state.error}
            {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}</> : <Loader />}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return { user: state.user, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getUser, userSignIn, updateUser, updatePasswordUser, userSignOut, passwordReminderUser, changeLanguage }
    )(UserController);