import React from 'react';
import { connect } from 'react-redux';
import { getXmlSchemas, deleteXmlSchema } from '../actions/index';
import { getCookie } from '../helpers/cookies';
import Loader from './Loader';
import Message from './Message';
import { baseURL, serverbaseURL } from '../apis';

class SchemasView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            xmlSchemas: [],
            loaded: false,
            empty: ""
        }
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
            await this.props.getXmlSchemas(token);
            this.setState({xmlSchemas: this.props.schemas})
            } catch(err) {
                if(this.props.language.language==="en") {
                this.setState({empty: err.response.data.en})
                } else {
                    this.setState({empty: err.response.data.pl})
                }
            }
        }
        this.setState({loaded:true})
    }

    handleDelete = async() => {

    }

    renderSchemas = () => {
        return this.state.xmlSchemas.map(xmlSchema => {
            return (
            <tr>
                <th scope="row">
                    {xmlSchema.name}
                </th>
                <td>
                    <i id={xmlSchema._id} onClick={this.handleDelete} style={{margin:'10px'}} className="fas fa-trash"></i>
                    <a href={`${baseURL}/panel/schemas/edit/${xmlSchema._id}`}><i className="far fa-edit"></i></a>
                </td>
            </tr>
        )
        })
    }
    render() {
        const { language } = this.props.language;
        return(
        <>{this.state.loaded ? 
            <div>
            <a href={`${baseURL}/panel/schemas/add`}>
                <button className="btn btn-primary">
                {language==="en" ?
                <>New XML Schema</> :
                <>Dodaj Schemat XML</>}
                </button>
            </a>
            
            <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">
                {language==="en" ?
                <>Name</> :
                <>Nazwa</>}
                </th>
                <th scope="col">
                {language==="en" ?
                <>Actions</> :
                <>Akcje</>}
                </th>
              </tr>
            </thead>
            <tbody>
                {this.renderSchemas()}
            </tbody>
          </table></div> : <Loader />}
          {this.state.empty}
          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
          </>
        )
    }
}

const mapStateToProps = (state) => {
    return { admin: state.admin, schemas: state.schemas, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getXmlSchemas, deleteXmlSchema }
    )(SchemasView);

