import React from 'react';
import { connect } from 'react-redux';
import { getMachines, getReport, createReport, updateReport, deleteReport, getTypes } from '../actions'
import { baseURL } from '../apis';
import { getCookie } from '../helpers/cookies';
import RaportName from './RaportName';
import Message from './Message';
import Loader from './Loader';
import TypesList from './TypesList';

function Options({reportSchema}) {
    if(reportSchema==="classic") {
    return (
        <>
        <option selected value="classic">Classic</option>
        <option value="terg">Terg</option>
        </>
    )
    } else {
        return (
            <>
        <option value="classic">Classic</option>
        <option selected value="terg">Terg</option>
        </>
        )
    }
}

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            machines: [],
            error: '',
            allMachines: [],
            checkedMachines: [],
            details: null,
            showRaportName: false,
            error: '',
            raports: [],
            raportType: 'new-raport',
            raportName: '',
            showMessage: false,
            message: '',
            types: [],
            loaded: false,
            update: false,
            reportName: '',
            empty: '',
            reportSchema: ""
        }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {

        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    componentDidMount = async () => {
        
        const token = getCookie('jwt_access_tkn') || getCookie('jwt_access');

        if(token) {
            try {
            await this.props.getMachines(token);
            await this.props.getTypes(token);
            this.setState({machines: this.props.machines});
            this.setState({allMachines: this.props.machines});
            this.setState({types: this.props.types})
            }catch (err) {
                if(err.response.data) {
                    this.setState({machines: []})
                if(this.props.language.language==="en") {
            this.setState({empty: err.response.data.en});
          } else {
            this.setState({empty: err.response.data.pl});
          }
                }
            }
        }
        if(this.props.match.params.reportId) {

            try {
                this.setState({update: true})
                await this.props.getReport(this.props.match.params.reportId, token);
                let machines = this.props.report.machines.map(el => {
                    return {
                        _id: el._id,
                        price: el.purchasePrice,
                        indeks: el.indeks
                    }
                })
                this.setState({reportName: this.props.report.name});
                this.setState({checkedMachines: machines});
                this.setState({reportSchema: this.props.report.reportSchema});
            } catch(err) {
                if(this.props.language.language==="en") {
            this.setState({empty: err.response.data.en});
          } else {
            this.setState({empty: err.response.data.pl});
          };
            }
        }

        this.setState({loaded: true})
    }

    handleName = e => {
        this.setState({error: ''})
        this.setState({raportName: e.target.value})
    }
    handleSchema = e => {
        this.setState({error: ""})
        this.setState({reportSchema: e.target.value});
    }

    closeRaport = () => {
        let mask = document.getElementById('mask');
        mask.parentNode.removeChild(mask);
        this.setState({error: ''})
        this.setState({showRaportName: false});
        this.setState({raportType: 'new-raport'});
        this.setState({raportName: ''});
    }

    confirmRaport = async () => {
        this.setState({error: ''})
        let array = Array.from(document.querySelectorAll('.input-sell-price'));
        let error = false;
        array.forEach(el=>{
            if(isNaN(el.value) || el.value===0 || el.value==='' && !el.disabled) {
                error = true
                el.style.border='2px solid red';
            }
        });
        if(error) {
            return this.setState({error: 'Podane kwoty muszą być liczbami!'})
        }
        let root = document.getElementById("root");
        let mask = document.createElement("div");
        mask.setAttribute("id", "mask");
        root.after(mask);
        
        this.setState({showRaportName: true});
    }


    createRaport = async () => {
        let raportName = this.state.raportName
        if(raportName==='') {
            return this.setState({error: 'Musisz podać nazwę pliku!!!'})
        }

        if(this.state.reportSchema==="") {
            this.setState({error: 'Musisz wybrać schemat raportu!/ You have to choise report schema!'})
            return;
        }
        let string = raportName.slice(-4);

        if(!raportName.match(/^[a-z0-9]+$/i) && string!=='.xml') { return this.setState({error: 'Nazwa nie może zawierać żadnych znaków specjalnych!'}) }
        if(string!=='.xml') {
            raportName+='.xml';
        }
            const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.createReport(this.state.checkedMachines, raportName, this.state.reportSchema, token);
                if(this.props.language.language==="en") {
                        this.showMessage(this.props.message.en) }
                        else { this.showMessage(this.props.message.pl )}                
                        setTimeout( () => {
                    window.open(this.props.message.path, '_blank');
                }, 5000)
            } catch(err) {
                if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }
            }
        }
    }

    updateReport = async () => {
        console.log(this.state.reportSchema)
        this.setState({error: ''})
        let array = Array.from(document.querySelectorAll('.input-sell-price'));
        let error = false;
        array.forEach(el=>{
            if(isNaN(el.value) || el.value===0 || el.value==='' && !el.disabled) {
                error = true
                el.style.border='2px solid red';
            }
        });
        if(error) {
            return this.setState({error: 'Podane kwoty muszą być liczbami!'})
        }
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
                await this.props.updateReport(this.props.match.params.reportId, this.state.checkedMachines, this.state.reportSchema, token);
                if(this.props.language.language==="en") {
                        this.showMessage(this.props.message.en) }
                        else { this.showMessage(this.props.message.pl )}                
                        setTimeout( () => {
                    window.open(this.props.message.path, '_blank');
                }, 5000)
            } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }            }
        }
    }

    handleChange = e => {
        this.setState({error: ''})
        let array = Array.from(document.querySelectorAll('.input-sell-price'));
        let error=false;
        array.forEach(el=>{
            // el.value = el.value.replace(',', '.');
            if(isNaN(el.value) || el.value===0 || el.value==='' && !el.disabled) {
                error = true
                el.style.border='2px solid red';
            } else {
                el.style.border='1px solid gray';
            }
        });
        if(e.target.name==='sellPrice') {
            let price = e.target.value.replace(',', '.');
            let checked = this.state.checkedMachines.map(el => {
                if(el._id===e.target.attributes.dataPriceId.value) {
                    return {
                        _id: el._id,
                        price: price,
                        indeks: el.indeks
                    }
                } else {
                    return {
                        _id: el._id,
                        price: el.price,
                        indeks: el.indeks
                    }
                }
            })
            this.setState({checkedMachines:checked});
        } else if(e.target.name ==="indeks") {
            let checked = this.state.checkedMachines.map(el => {
                if(el._id===e.target.attributes.dataIndeksId.value) {
                    return {
                        _id: el._id,
                        price: el.price,
                        indeks: e.target.value
                    }
                } else {
                    return {
                        _id: el._id,
                        price: el.price,
                        indeks: el.indeks
                    }
                }
            })
            this.setState({checkedMachines:checked});

        } else if(e.target.name==="selectSchema") {
            this.setState({reportSchema: e.target.value})
        }

    }

    allCheckbox(param) {
        let collection = document.querySelectorAll('.checkbox-input');
            collection = Array.from(collection);
        if(param) {
            collection.forEach( check => {
            check.checked = true;
            })
        } else {
            collection.forEach( check => {
                check.checked = false;
            })
        }
    }

    handleCheckbox = e => {
        if(e.target.id==='check-all') {
            if(e.target.checked) {
                this.allCheckbox(true);
                let checked = this.state.machines.map(machine => {
                    return {
                        _id: machine._id,
                        price: '',
                        indeks: ''
                    }
                });
                this.setState({checkedMachines: checked});
            } else {
                this.allCheckbox(false);
                this.setState({checkedMachines: []});
            }
        } else {
            if(e.target.checked) {
                let checked = this.state.checkedMachines;
                checked.push({
                    _id: e.target.id,
                    price: '',
                    indeks: ''
                });
                this.setState({checkedMachines:checked});
                if(this.state.checkedMachines.length===this.state.machines.length && this.state.checkedMachines.length!==0) { let checkboxAll = document.getElementById('check-all');
                checkboxAll.checked = true; }
            } else {
                let checked = this.state.checkedMachines.filter( el => el._id!==e.target.id);
                let checkboxAll = document.getElementById('check-all');
                checkboxAll.checked = false;
                this.setState({checkedMachines:checked});
            }
        }
    }

    handleDetails = e => {
        if(!e.target.attributes.details) {
            return
        }
        if(this.state.details===e.target.attributes.details.value) {
            this.setState({details: null})
        } else {
        this.setState({details: e.target.attributes.details.value})
        }

    }

    handleSort = e => {
        let machines = this.state.machines;
        switch(e.target.attributes.subject.value) {
            case 'price':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.marketPrice-b.marketPrice})
                else {
                    machines.sort(function (a,b) { return b.marketPrice-a.marketPrice})
                }
            break;
            case 'model':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function(a,b) { if (a.model<b.model)
                    return -1
                 if (a.model> b.model)
                    return 1
                 return 0})
                else {
                    machines.sort(function(a,b) { if (a.model>b.model)
                        return -1
                     if (a.model<b.model)
                        return 1
                     return 0})
                }
                break;
                case 'type':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function(a,b) { if (a.type<b.type)
                    return -1
                 if (a.type> b.type)
                    return 1
                 return 0})
                else {
                    machines.sort(function(a,b) { if (a.type>b.type)
                        return -1
                     if (a.type<b.type)
                        return 1
                     return 0})
                }
            break;
            case 'quantity':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.quantity-b.quantity})
                else {
                    machines.sort(function (a,b) { return b.quantity-a.quantity})
                }
            break;
            case 'deliveryDate':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.deliveryDate-b.deliveryDate})
                else {
                    machines.sort(function (a,b) { return b.deliveryDate-a.deliveryDate})
                }
            break;
            case 'updateDate':
                if(e.target.attributes.sort.value==="up")
                machines.sort(function (a,b) { return a.updateInfo[a.updateInfo.length-1].date-b.updateInfo[b.updateInfo.length-1].date})
                else {
                    machines.sort(function (a,b) { return b.updateInfo[b.updateInfo.length-1].date-a.updateInfo[a.updateInfo.length-1].date})
                }
            break;
            default:
                break;
        }

        this.setState({machines: machines})


    }

    handleSearch = e => {
        let machines;
        if(e.target.value==='') {
            machines = this.state.allMachines;
        } else {
            machines = this.state.allMachines.filter(el => {
                let regexp = new RegExp(e.target.value, 'i');
                return regexp.test(el.model);
            }
                );
        };

        this.setState({machines: machines})
    }

    handleFilter = e => {
        let machines;
        if(e.target.value==="") {
            machines = this.state.allMachines;
        } else {
            machines = this.state.allMachines.filter(function(element) { return element.type===e.target.value});
        }

        this.setState({machines: machines});

    }

    renderMachines() {
        const {language} = this.props.language;
        return this.state.machines.map((machine, index) => {
            const machineType = this.state.types.filter( el => { 
                if(el._id === machine.type) { return el.name}
            }) 
            let isChecked = this.state.checkedMachines.filter(el => el._id===machine._id);
            let weight = machine.weight;
            let boxHeight = machine.boxHeight;
            let boxLength = machine.boxLength;
            let boxWidth = machine.boxWidth;
            let EAN = machine.EAN;
            let marketPrice = machine.marketPrice;
            if(machine.weight===0) {
                weight = 'N/A'
            }
            if(machine.EAN==="Not Applicable") {
                EAN = 'N/A'
            }
            if(machine.boxHeight===0) {
                boxHeight = 'N/A'
            }
            if(machine.boxLength===0) {
                boxLength = 'N/A'
            }
            if(machine.boxWidth===0) {
                boxWidth = 'N/A'
            }
            if(machine.marketPrice===0) {
                marketPrice = 'N/A'
            }

            if(isChecked.length>0) {
            return (
                <>
                <tr className="checked-row">
                {this.props.admin.login ? <><th>
                    <input type="checkbox" 
                checked
                className="checkbox-input" onChange={this.handleCheckbox} id={machine._id}/>
                
                    <input
                    className="input-sell-price ml-2"
                    type="text"
                    value={isChecked[0].price}
                    onChange={this.handleChange}
                    name="sellPrice"
                    dataPriceId={machine._id}
                    />
                    </th>
                    <th>
                    <input
                    type="text"
                    value={isChecked[0].indeks}
                    className="input-indeks ml-2"
                    onChange={this.handleChange}
                    name="indeks"
                    placeholder="Wpisz indeks"
                    dataIndeksId={machine._id}
                    />
                
                </th></> : null}
                    <th scope="row">{machine.model}</th>
                    <td>{machineType[0].name}</td>
                    <td>{marketPrice}</td>
                    <td>{machine.quantity}</td>
                    <td>{machine.description}</td>
                    <td className="table-more">
                        <div className="tooltip-show"><i onClick={this.handleDetails} details={machine._id}className="fas fa-info-circle"><span className="tooltip-text">
                            {language==="en" ?
                            <>Details</> :
                            <>Zobacz szczegóły</>}
                            </span></i></div>
                    </td>
                </tr>
                {this.state.details===machine._id ? <tr className="details-row"><td colspan="8"><ul>
                    <li><b>
                        {language==="en" ?
                        <>EAN Number:</> :
                        <>Numer EAN:</>}
                    </b> {EAN}</li> 
                    <li><b>
                    {language==="en" ?
                        <>Gross weight:</> :
                        <>Waga brutto:</>}
                        </b> {weight} kg</li> 
                    <li><b>
                    {language==="en" ?
                        <>Dimensions (L x W x H):</> :
                        <>Wymiary (dł. x szer. x wys.):</>}
                        </b> {boxLength} cm x {boxWidth} cm x {boxHeight} cm</li> 
                    {machine.link!=='' ? <li><b>Link:</b> <a href={machine.link} target="_blank">CLICK</a></li>:null}
                    </ul></td></tr> : null}
                </>
        )
            } else if(index%2===0) {
                return (<>
                <tr>
                {this.props.admin.login ? <><th><input type="checkbox" className="checkbox-input" onChange={this.handleCheckbox} id={machine._id}/>
                <input
                    type="text"
                    disabled={true}
                    value=''
                    className="input-sell-price ml-2"
                    onChange={this.handleChange}
                    name="sellPrice"
                    dataPriceId={machine._id}/>
                    </th>
                    <th>
                    <input
                    type="text"
                    disabled={true}
                    className="input-indeks ml-2"
                    value=""
                    onChange={this.handleChange}
                    name="indeks"
                    dataIndeksId={machine._id}
                    />
                    
                    </th></> : null}
                    <th scope="row">{machine.model}</th>
                    <td>{machineType[0].name}</td>
                    <td>{marketPrice}</td>
                    <td>{machine.quantity}</td>
                    <td>{machine.description}</td>
                    <td className="table-more">
                        <div className="tooltip-show"><i onClick={this.handleDetails} details={machine._id}className="fas fa-info-circle"><span className="tooltip-text">
                        {language==="en" ?
                            <>Details</> :
                            <>Zobacz szczegóły</>}
                            </span></i></div>
                    </td>
                </tr>
                {this.state.details===machine._id ? <tr className="details-row"><td colspan="8"><ul>
                    <li><b>
                        {language==="en" ?
                        <>EAN Number:</> :
                        <>Numer EAN:</>}
                    </b> {EAN}</li> 
                    <li><b>
                    {language==="en" ?
                        <>Gross weight:</> :
                        <>Waga brutto:</>}
                        </b> {weight} kg</li> 
                    <li><b>
                    {language==="en" ?
                        <>Dimensions (L x W x H):</> :
                        <>Wymiary (dł. x szer. x wys.):</>}
                        </b> {boxLength} cm x {boxWidth} cm x {boxHeight} cm</li> 
                    {machine.link!=='' ? <li><b>Link:</b> <a href={machine.link} target="_blank">CLICK</a></li>:null}
                    </ul></td></tr> : null}               </>)
            } else {
                return (
                    <>
                    <tr className="striped-row">
                    {this.props.admin.login ? 
                    <><th><input type="checkbox" className="checkbox-input" onChange={this.handleCheckbox} id={machine._id}/>
                    <input
                    type="text"
                    className="input-sell-price ml-2"
                    onChange={this.handleChange}
                    name="sellPrice"
                    disabled={true}
                    value=''
                    dataPriceId={machine._id}/>
                    </th>
                    <th>
                    <input
                    type="text"
                    disabled={true}
                    className="input-indeks ml-2"
                    value=""
                    onChange={this.handleChange}
                    name="indeks"
                    dataIndeksId={machine._id}
                    />
                    </th></> : null}
                        <th scope="row">{machine.model}</th>
                        <td>{machineType[0].name}</td>
                        <td>{marketPrice}</td>
                        <td>{machine.quantity}</td>
                        <td>{machine.description}</td>
                        <td className="table-more">
                            <div className="tooltip-show"><i onClick={this.handleDetails} details={machine._id}className="fas fa-info-circle"><span className="tooltip-text">
                            {language==="en" ?
                            <>Details</> :
                            <>Zobacz szczegóły</>}
                                </span></i></div>
                        </td>
                    </tr>
                    {this.state.details===machine._id ? <tr className="details-row"><td colspan="8"><ul>
                    <li><b>
                        {language==="en" ?
                        <>EAN Number:</> :
                        <>Numer EAN:</>}
                    </b> {EAN}</li> 
                    <li><b>
                    {language==="en" ?
                        <>Gross weight:</> :
                        <>Waga brutto:</>}
                        </b> {weight} kg</li> 
                    <li><b>
                    {language==="en" ?
                        <>Dimensions (L x W x H):</> :
                        <>Wymiary (dł. x szer. x wys.):</>}
                        </b> {boxLength} cm x {boxWidth} cm x {boxHeight} cm</li> 
                    {machine.link!=='' ? <li><b>Link:</b> <a href={machine.link} target="_blank">CLICK</a></li>:null}
                    </ul></td></tr> : null}                  </>
            )
            }
        })

    }

    render() {
        const {language} = this.props.language;
        return (
            <div>
                {this.state.loaded ? <>{<h2 className="flex">{this.state.reportName}</h2>} <table className="table">
            <thead>
              <tr>
                {this.props.admin.login ? 
                <><th>
                    <div className="flex-not-center">
                    <input type="checkbox" onChange={this.handleCheckbox} id="check-all"/>
                        <div className="pl-2">
                            {language==="en" ? 
                            <>Purchase Price</> :
                            <>Cena sprzedażowa</>}
                        </div>
                    </div>
                </th> 
                <th>
                    Indeks
                </th></>
                    : null}
                <th>
                    <div className="flex-not-center">
                    Model
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="model" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="model" className="sort-icon"></img>
                    </div>
                    </div>
                    {language==="en" ?
                <input
                type="text"
                placeholder="Search model..."
                onChange={this.handleSearch}
                name="model"
                /> :
                <input
                type="text"
                placeholder="Wpisz model..."
                onChange={this.handleSearch}
                name="model"
                />}
                </th>
                <th scope="col">
                <div className="flex-not-center">
                {language==="en" ? 
                            <>Type</> :
                            <>Typ</>} 
                    </div>

                    <select onChange={this.handleFilter}>
                        {language==="en" ?
                        <option value="">All types</option> :
                        <option value="">Wszystkie typy</option>}
                        <TypesList types={this.state.types}/>
                    </select>
                </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ? 
                            <>Recommended Retail Price</> :
                            <>Sugerowana cena detaliczna</>} 
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="price" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="price" className="sort-icon"></img>
                    </div>
                    </div>
                </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ? 
                            <>Quantity</> :
                            <>Stan</>} 
                    <div className="pl-2 flex-column">
                    <img src={`${baseURL}/up.svg`} onClick={this.handleSort} sort="up" subject="quantity" className="sort-icon"></img>
                    <img src={`${baseURL}/down.svg`} onClick={this.handleSort} sort="down" subject="quantity" className="sort-icon"></img>
                    </div>
                    </div>
                </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ? 
                            <>Other description</> :
                            <>Uwagi</>}
                    </div>
                    </th>
                <th scope="col">
                    <div className="flex-not-center">
                    {language==="en" ? 
                            <>More</> :
                            <>Więcej</>}
                    </div>
                </th>
              </tr>
            </thead>
            <tbody>
                {this.state.empty ? <>{this.state.empty}</> : <>{this.renderMachines()}</> }
            </tbody>
          </table>
                    
</> : <Loader/>}
          
          <>{this.state.checkedMachines.length > 0 ? this.state.update ? 
          <>{language==="en" ? <label>Select schema: </label> : <label>Wybierz schemat: </label>}
            <select style={{marginLeft: "15px"}}name="selectSchema" onChange={this.handleChange}>
              <Options reportSchema={this.state.reportSchema}/>
            </select>
            <br />
            <br />
          <button onClick={this.updateReport} className="btn btn-primary">
              {language==="en" ? 
                <>SAVE REPORT</> :
                <>ZAPISZ RAPORT</>}
              </button></> : 
          <button onClick={this.confirmRaport} className="btn btn-success">
              {language==="en" ? 
                <>CREATE REPORT</> :
                <>STWÓRZ RAPORT</>}
              </button> : 
          <button disabled className="btn btn-success">
              {language==="en" ? 
                <>CHECK MACHINES</> :
                <>ZAZNACZ MASZYNY</>}
              </button>}</>
          {this.state.showRaportName ? <RaportName error={this.state.error} name={this.state.raportName} handleName={this.handleName} handleSchema={this.handleSchema} closeRaport={this.closeRaport} createRaport={this.createRaport} /> : null}
          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { machines: state.machines, admin: state.admin, raports: state.raports, machine: state.machine, report: state.report, types: state.types, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getMachines, getReport, createReport, updateReport, deleteReport, getTypes }
    )(Reports);