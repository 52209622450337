import React from 'react';
import { connect } from 'react-redux';
import { getReportsNames, deleteReport } from '../actions/index';
import { getCookie } from '../helpers/cookies';
import Loader from './Loader';
import Message from './Message';
import { baseURL, serverbaseURL } from '../apis';


class ReportsView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            reportsToView: [],
            loaded: false,
            reportSearch: '',
            empty: '',
            showMessage: false,
            message: ''
        }
    }

    showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {

        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
            await this.props.getReportsNames(token);
            this.setState({reports: this.props.reports})
            this.setState({reportsToView: this.props.reports});
            } catch(err) {
                if(this.props.language.language==="en") {
                this.setState({empty: err.response.data.en})
                } else {
                    this.setState({empty: err.response.data.pl})
                }
            }
        }
        this.setState({loaded:true})
    }

    handleDelete = async e => {
        e.stopPropagation();
        if(!e.target.attributes.dataDelete) {
            return
        }
        const token = getCookie('jwt_access_tkn');
        if(token) {
            try {
            await this.props.deleteReport(e.target.attributes.dataDelete.value, token);
            if(this.props.language.language==="en") {
                    this.showMessage(this.props.message.en) }
                    else { this.showMessage(this.props.message.pl )}  
                  setTimeout( () => window.location.reload(), 5000)
            } catch(err) {
                if(this.props.language.language==="en") {
                            this.showMessage(err.response.data.en, true);
                        } else {
                            this.showMessage(err.response.data.pl, true);
          }                  }

        }
    }

    handleChange = e => {
        let reports;
        this.setState({reportSearch: e.target.value});
        if(e.target.value==='') {
            reports = this.state.reports;
        } else {
            reports = this.state.reports.filter(el => el.name.includes(e.target.value));
        }

        this.setState({reportsToView: reports});

    }

    renderReports = () => {
        return this.state.reportsToView.map(el => {
            return (<div className="reports-card flex-column">
                <h4>{el.name}</h4>
                <a href={`${serverbaseURL}/files/xml/${el.name}`} target="_blank"><img className="xml-image" src={`${baseURL}/xml-image.png`} /></a>
                <div className="flex buttons-container"><a className="link" href={`${baseURL}/panel/reports/${el._id}`}><button className="btn btn-primary"><i className="fas fa-edit"></i></button></a><button onClick={this.handleDelete} dataDelete={el._id} className="btn btn-danger"><i onClick={this.handleDelete} dataDelete={el._id} className="fas fa-trash"></i></button>
                </div>
            </div>
            )
        })
    }

    render() {
        const {language} = this.props.language;
        return(
            <div className="flex flex-column">
                <div className="flex reports-header">
                    <a href={`${baseURL}/panel/reports/new`}><button className="btn btn-success">
                        {language==="en" ?
                    <>NEW REPORT</> :
                    <>DODAJ RAPORT</>}
                    </button></a>
                    {language==="en" ? 
                    <input
                    type="text"
                    name="reportSearch"
                    onChange={this.handleChange}
                    placeholder="Search for report..."
                    /> :
                    <input
                    type="text"
                    name="reportSearch"
                    onChange={this.handleChange}
                    placeholder="Wyszukaj raport..."
                    />}
                    {/* <a href={`${baseURL}/panel/schemas`}><button className="btn btn-primary">
                        {language==="en" ?
                    <>XML SCHEMAS</> :
                    <>SCHEMATY XML</>}
                    </button></a> */}
                </div>
                {this.state.loaded ? 
                <div className="reports-container">{this.renderReports()}</div> : <Loader/>}
                {this.state.empty}
                {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { admin: state.admin, reports: state.reports, machines: state.machines, report: state.report, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getReportsNames, deleteReport }
    )(ReportsView);