import React from 'react';
import { connect } from 'react-redux';
import { getTypes, createType, updateType, deleteType } from '../actions/index';
import { getCookie } from '../helpers/cookies';
import Loader from './Loader';
import Message from './Message';
import EditInput from './EditInput';

class Types extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [],
            isError: false,
            message: '',
            showMessage: false,
            update: false,
            add: false,
            toEdit: '',
            editName: ''
        }
    }

        showMessage = async (message, isError) => {
        this.setState({showMessage: false});
        this.setState({message: message});
        if(isError) {
        this.setState({isError: isError})
        } else {
        
        this.setState({isError: false})
        }
        this.setState({showMessage: true});
        setTimeout( () => {
            this.setState({showMessage: false})
            this.setState({message: ''})
        }, 5000);
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        try {
            await this.props.getTypes(token)
            this.setState({types: this.props.types})
        } catch(err) {
            if(this.props.language.language==="en") {
                this.setState({error: err.response.data.en})
            } else {
                this.setState({error: err.response.data})
            }
        }
        this.setState({loaded: true})
    }

    componentDidUpdate = async () => {
        if(this.state.update) {
            const token = getCookie('jwt_access_tkn');
            try {
                await this.props.getTypes(token);
            this.setState({types: this.props.types});
            this.setState({update: false})
            } catch (err) {
                this.setState({types: []})
                if(this.props.language.language==="en") {
            this.setState({error: err.response.data.en});
          } else {
            this.setState({error: err.response.data.pl});
          }
            }
            this.setState({update: false});
        }
    }


    handleChange = e => {
        switch(e.target.name) {
            case 'name':
                this.setState({name: e.target.value});
                break;
            case 'edit-name':
                this.setState({editName: e.target.value});
                break;
            default:
                break;
            
        }
    }

    handleCreate = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        const { name } = this.state;
        try {
            await this.props.createType(name, token);
if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl)
        };            
        this.setState({name:''});
            let inputs = Array.from(document.querySelectorAll('.form-control'));
            inputs.forEach(el => {
                el.value=''
            })
            this.setState({update: true})

        } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        }
    }

    handleUpdate = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        const { editName, toEdit } = this.state;
        try {
            await this.props.updateType(toEdit, editName, token);
if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl)
        };            
        this.setState({toEdit:''});
            this.setState({editName: ''});
            this.setState({update: true});

        } catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        }
    }

    handleCancel = e => {
        e.preventDefault();
        this.setState({toEdit: ''});
        this.setState({editName: ''});
    }

    handleForm = () => {
        if(this.state.add) {
            this.setState({add: false})
        } else {
            this.setState({add:true})
        }
    }

    handleEdit = e => {

        this.setState({toEdit: e.target.attributes.edit.value});
        this.setState({editName: e.target.innerText});

    }

    renderTypes = () => {
        return this.state.types.map(type => {
            return (
            <tr>
                <th scope="row">
                    {this.state.toEdit===type._id ? <EditInput value={this.state.editName} change={this.handleChange} update={this.handleUpdate} cancel={this.handleCancel}/> : <div edit={type._id} className="type-name" onClick={this.handleEdit}>{type.name}</div>}
                </th>
                <td><i id={type._id} onClick={this.handleDelete} style={{margin:'10px'}} className="fas fa-trash"></i></td>
            </tr>
        )
        })
    }

    handleDelete = async e => {
        e.preventDefault();
        const token = getCookie('jwt_access_tkn');
        try {
        await this.props.deleteType(e.target.id, token);
if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl)
        };        
        this.setState({update: true});
        }
        catch(err) {
if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }        }

    }

       

    render() {
        const { language } = this.props.language;
        return(<>{this.state.loaded ? 
            <div>
            <button onClick={this.handleForm} className="btn btn-primary">
                {language==="en" ?
                <>New type</> :
                <>Dodaj Typ</>}
                </button>
            <p>
            {language==="en" ?
                <>* Click on type name to edit</> :
                <>* Kliknij na nazwę typu, żeby zmienić jego nazwę</>}
                
            </p>
            {this.state.add ? 
            <form>
                <div className="mb-3">
                <label for="name-input" className="form-label">
                {language==="en" ?
                <>Name</> :
                <>Nazwa</>}
                    </label>
                <input onChange={this.handleChange} type="text" className="form-control" id="name-input" name="name"/>
            </div>
            <button onClick={this.handleCreate} type="submit" className="btn btn-primary">
            {language==="en" ?
                <>ADD</> :
                <>Dodaj</>}
                </button>
            </form>
             : null}
            <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">
                {language==="en" ?
                <>Name</> :
                <>Nazwa</>}
                    </th>
                <th scope="col">
                {language==="en" ?
                <>Actions</> :
                <>Akcje</>}
                    </th>
              </tr>
            </thead>
            <tbody>
                {this.renderTypes()}
            </tbody>
          </table></div> : <Loader />}
          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
</>
        )
    }
}

const mapStateToProps = (state) => {
    return { types: state.types, type: state.type, language: state.language, message: state.message };

};

export default connect(
    mapStateToProps,
    { getTypes, createType, updateType, deleteType }
    )(Types);

