import React from 'react';
import { connect } from 'react-redux';
import { getXmlSchema, createXmlSchema, updateXmlSchema, deleteXmlSchema } from '../actions/index';
import { getCookie } from '../helpers/cookies';
import Loader from './Loader';
import Message from './Message';
import { baseURL, serverbaseURL } from '../apis';
import Attribute from './Attribute';

class SchemaForm extends React.Component {
    constructor(props) {
        super(props) 
        this.state = {
            name: '',
            document: {
                name: '',
                attributes: []
            },
            product: [],
            loaded: false,
            documentAttributes: []
        }
    }

    componentDidMount = async () => {
        const token = getCookie('jwt_access_tkn');
        if(this.props.match && this.props.match.params.xmlSchemaId) {
        try {
            await this.props.getSchema(token);
            this.setState({name: this.props.schema.name});
            this.setState({document: this.props.schema.document});
            this.setState({product: this.props.schema.product});
        } catch(err) {
            console.log(err.response.data)
        }
    }
    this.setState({loaded: true})
    console.log(this.state.document)
    
    }

    handleChange = e => {
        let product = [];
        let doc = {};
        let attributes = [];
        switch(e.target.name) {
            case 'document-name':
                doc = this.state.document;
                doc.name = e.target.value;
                this.setState({document: doc});
                break;
            case 'document-attribute-name':
                doc = this.state.document;
                let array = e.target.id.split('-');
                let id = array[array.length-1];
                let existing = false;
                if(doc.attributes.length > 0) {
                attributes = doc.attributes.map(attribute => {
                    if(attribute.id===id) {
                        attribute.name=e.target.value;
                        existing = true
                    }
                    return attribute
                });
            }
                if(!existing) {
                    let val = document.getElementById(`document-attribute-value-${id}`).value;
                    attributes.push({
                        id: id,
                        name: e.target.value,
                        value: val
                    })
                }
                doc.attributes = attributes;
                this.setState({document: doc});
                break;
                case 'document-attribute-value':
                    let arr = e.target.id.split('-');
                    doc = this.state.document;
                    attributes = doc.attributes.map(attribute => {
                        if(attribute.id === arr[arr.length-1]) {
                        attribute.value = e.target.value;
                        attribute.name = attribute.name;
                        }
                        return attribute;
                    })
                    if(e.target.value==="text") {
                        let docAttr = this.state.documentAttributes;
                        let ind;
                        docAttr.forEach((component, index) => {
                            if(component.props.idValue===e.target.id) {
                                ind = index;
                            }
                        })
                        docAttr.splice(ind, 1, <Attribute idAttribute={`document-attribute-${arr[arr.length-1]}`} nameName={"document-attribute-name"} idName={`document-attribute-name-${arr[arr.length-1]}`} nameValue={"document-attribute-value"} idValue={`document-attribute-value-${arr[arr.length-1]}`} language={this.props.language.language} selectValue={"text"} handleChange={this.handleChange}/>)
                        this.setState({documentAttributes:docAttr})
                    }
                    doc.attributes = attributes;
                    this.setState({document: doc});
                    break;
            default: 
            break;
        }
        console.log(this.state.document);

    }

    addAttribute = e => {
        e.preventDefault();
        let attributes;
        if(e.target.id==="document-attr-add") {
            let inputName = document.createElement('input');
            inputName.setAttribute("id", "document-attribute-name")
            inputName.setAttribute("name", "document-attribute-name")
            attributes = document.getElementById("document-attributes");
            console.log(attributes.childElementCount);
            const attributeCount = attributes.childElementCount + 1;
            let documentAttributes = this.state.documentAttributes;

            documentAttributes.push(<Attribute idAttribute={`document-attribute-${attributeCount}`} nameName={"document-attribute-name"} idName={`document-attribute-name-${attributeCount}`} nameValue={"document-attribute-value"} idValue={`document-attribute-value-${attributeCount}`} language={this.props.language.language} selectValue={""} handleChange={this.handleChange}/>);
            this.setState({documentAttributes: documentAttributes});
        }

        console.log(this.state.documentAttributes)
        console.log(this.state.document);
    }

    render() {
        const {language} = this.props.language;
        return (
            <>{this.state.loaded ? 
            <form>
                <label>Dokument</label>
                <div className="mb-3">
                    <label for="document-name-input" className="form-label">
                        {language==="en" ?
                        <>Tag Name</> :
                        <>Nazwa taga</>}
                    </label>
                    <input onChange={this.handleChange} type="text" name="document-name" className="form-control" id="document-name-input"/>
                </div>
                <label>Atrybuty</label>
                <div id="document-attributes">
                    {this.state.documentAttributes}
                </div>
                <button id="document-attr-add" onClick={this.addAttribute}>{language==="en" ? "Add attribute" : "Dodaj atrybut"}</button>
                
                
            </form> 
            : <Loader/>}
            {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return { admin: state.admin, schema: state.schema, language: state.language, message: state.message };
};

export default connect(
    mapStateToProps,
    { getXmlSchema, createXmlSchema, updateXmlSchema, deleteXmlSchema }
    )(SchemaForm);