import React from 'react';

// document.getElementsByClassName('main-site')[0].onscroll = function() {
//     let button = document.querySelector('.icon-up');

//     let aboutSection = document.querySelector('.about-section');
//     let offerSection = document.querySelector('.offer-section');
//     let header = document.querySelector('header');
//     let photoHeader = document.querySelector('.photo-header');
//     let mask = document.querySelector('.mask');
//     const headerSection = mask.clientHeight + photoHeader.clientHeight;


//     if(window.scrollY > header.clientHeight + (headerSection/2) - 100) {
//         let animationAbout1 = document.getElementById('animation-about-1');
//         let animationAbout2 = document.getElementById('animation-about-2');
//         let animationAbout3 = document.getElementById('animation-about-3');
//         let animation2 = document.getElementById('animation-2');
//         let animation3 = document.getElementById('animation-3');

//         // animationAbout1.style.display = 'flex'; 
//         // animationAbout2.style.display = 'flex'; 

//         // animationAbout3.style.display = 'flex'; 

//         // animation2.style.display = 'flex'; 
//         // animation3.style.display = 'flex'; 

//         animationAbout1.style.visibility = 'visible'; 
//         animationAbout1.style.animation = 'opacity-down 1.5s ease-in-out forwards'

//         animationAbout2.style.visibility = 'visible'; 
//         animationAbout2.style.animation = 'opacity-down 1.5s ease-in-out forwards'
//         animationAbout2.style.animationDelay = '0.5s'


//         animationAbout3.style.visibility = 'visible'; 
//         animationAbout3.style.animation = 'opacity-down 1.5s ease-in-out forwards'
//         animationAbout3.style.animationDelay = '1s'


//         animation2.style.visibility = 'visible'; 
//         animation2.style.animation = 'opacity-left 1.5s ease-in-out forwards';
//         animation2.style.animationDelay = '0.5s'

//         animation3.style.visibility = 'visible'; 
//         animation3.style.animation = 'opacity-right 1.5s ease-in-out forwards';
//         animation3.style.animationDelay = '1s'

//     }

//     if(window.scrollY > header.clientHeight + (headerSection*1.5) + aboutSection.clientHeight - 50) {
//         let animation4 = document.getElementById('animation-4');
//         // animation4.style.display = 'flex';
//         animation4.style.visibility = 'visible';
//         animation4.style.animation = 'opacity-right 1.5s ease-in-out forwards';
//         animation4.style.animationDelay = '0.5s';

//     }
//     if(window.scrollY > header.clientHeight + (headerSection*1.5) + aboutSection.clientHeight) {
//         let animation5 = document.getElementById('animation-5');
//         // animation5.style.display = 'flex';
//         animation5.style.visibility = 'visible';
//         animation5.style.animation = 'opacity-left 1.5s ease-in-out forwards';
//         animation5.style.animationDelay = '1s';

//     }

//     if(window.scrollY > header.clientHeight + (headerSection*1.5) + aboutSection.clientHeight + 50) {
//         let animation6 = document.getElementById('animation-6');
//         // animation6.style.display = 'flex';
//         animation6.style.visibility = 'visible';
//         animation6.style.animation = 'opacity-down 1.5s ease-in-out forwards';
//         animation6.style.animationDelay = '0.5s';

//     }

//     if(window.scrollY > header.clientHeight + (headerSection*3) + aboutSection.clientHeight + offerSection.clientHeight) {
//         let animation7 = document.getElementById('animation-7');
//         let animation8 = document.getElementById('animation-8');
//         let animation9 = document.getElementById('animation-9');

//         // animation7.style.display = 'flex'; 
//         // animation8.style.display = 'flex'; 
//         // animation9.style.display = 'flex'; 

//         animation7.style.visibility = 'visible'; 
//         animation7.style.animation = 'opacity-left 1.5s ease-in-out forwards';

//         animation8.style.visibility = 'visible'; 
//         animation8.style.animation = 'opacity-right 1.5s ease-in-out forwards';
//         animation8.style.animationDelay = '0.5s';

//         animation9.style.visibility = 'visible'; 
//         animation9.style.animation = 'opacity-right 1.5s ease-in-out forwards';
//         animation9.style.animationDelay = '1s';

//     }
      

//     if(window.scrollY > 100) {
//         button.style.display = 'block';
//     } else {
//         button.style.display = 'none';
//     }};

function wrapMenu() {
    console.log("wrap")
    let lineOne = document.querySelector('.line-one');
    let lineThree = document.querySelector('.line-three');
    let lineTwo = document.querySelector('.line-two');
    let wrappedMenu = document.querySelector('.wrapped-menu');
    if(Array.from(lineOne.classList).includes('isopen')) {
        lineOne.style.transform = "translate(0px, 0px) rotate(0deg)"
        lineOne.setAttribute('class', 'hamburger-line line-one');
        lineThree.style.transform = "translate(0px, 0px) rotate(0deg)";
        lineThree.setAttribute('class', 'hamburger-line line-three');
        // lineTwo.style.transform = "translate(0px, 0px)";
        lineTwo.setAttribute('class', 'hamburger-line line-two');
        setTimeout(() => {
            lineTwo.style.visibility = 'visible';
        },400)
        // lineTwo.style.visibility = 'visible';
        wrappedMenu.style.transform = 'translate(0px, 0px)';

    } else {
        lineOne.style.transform = "translate(0px, 12px) rotate(765deg)"
        lineOne.setAttribute('class', 'hamburger-line line-one isopen');
        lineThree.style.transform = "translate(0px, -12px) rotate(-765deg)";
        lineThree.setAttribute('class', 'hamburger-line line-three isopen');
        // lineTwo.style.transform = "translate(100px, 0px)";
        lineTwo.style.visibility = 'hidden';
        lineTwo.setAttribute('class', 'hamburger-line line-two isopen');
        wrappedMenu.style.transform = 'translate(0px, 400px)';

    }
}

function MainSitePl() {
    return(
        <div className="main-site">
        <div id="welcome" className="welcome flex-main column">
        <div id="container" className="flex-main stitch-container"></div>
        <div className="flex-main center"><img className="welcome-header" src="assets/logo.jpg"></img></div>
        <div id="back-container" className="flex-main stitch-container"></div>
    </div>
    <div className="site" style={{visibility: "hidden"}} id="site">
    <header>
        <nav className="flex-main nav desktop-nav between">
            <div className="flex-main links-container">
                <a href='#'><img className="logo" src="assets/logo.jpg"/></a>
                <div><a className="link" href="#about">O nas</a></div>
                <div><a className="link" href="#offer">Oferta</a></div>
                <div><a className="link" href="#contact">Kontakt</a></div>
                <div><a className="link" href="https://revorm.eu/machines">B2B</a></div>
            </div>

            <div className="flex-main">
                <a href="https://www.facebook.com/Minerva.Polska/" target="_blank"><i className="fab fa-facebook-square icon header-icon"></i></a>
                <a href="https://www.instagram.com/minerva_sewing_polska/" target="_blank"><i className="fab fa-instagram-square icon header-icon"></i></a>
                <a href="https://www.youtube.com/channel/UCkIRzjiV5C43QrWjcJzNeJg" target="_blank"><i className="fa fa-youtube-square icon header-icon"></i></a>
                <div><a href="/en"><img className="language-icon header-icon" src="assets/en.png"/></a></div>
            </div>

        </nav>
        <nav className="flex-main nav wrap mobile-nav" style={{display: "none"}}>
            <a className="link" href='#'><img className="logo" src="assets/logo.jpg"/></a>
            <div onClick={wrapMenu} className="menu-wrapper"><div className="hamburger-line line-one"></div><div className="hamburger-line line-two"></div><div className="hamburger-line line-three"></div></div>
            <div className="wrapped-menu">
                <a className="link" href="#about">O nas</a>
                <a className="link" href="#offer">Oferta</a>
                <a className="link" href="#contact">Kontakt</a>
                <a className="link" href="https://revorm.eu/machines">B2B</a>
                <div className="flex-main">
                    <a href="https://www.facebook.com/Minerva.Polska/" target="_blank"><i className="fab fa-facebook-square icon"></i></a>
                    <a href="https://www.instagram.com/minerva_sewing_polska/" target="_blank"><i className="fab fa-instagram-square icon"></i></a>
                    <a href="https://www.youtube.com/channel/UCkIRzjiV5C43QrWjcJzNeJg" target="_blank"><i className="fa fa-youtube-square icon"></i></a>
                </div>
                <div><a href="/en"><img className="language-icon" src="assets/en.png"/></a></div>
            </div>

        </nav>
    </header>

    <section className="section">
        <div className="flex-main center photo about"><div className="mask"></div></div>
        <h1 id="about" className="flex-main center photo-header">O NAS</h1>
        <div className="content flex-main wrap center baseline">
            <div className="about-section flex-main column baseline">
                <div className="paragraph dark flex-main column center">
                    
                    <div id="animation-about-1" className="flex-main center column"><i className="fas fa-building about-icon"></i><p className="about-paragraph">Jesteśmy firmą wchodzącą w skład międzynarodowej grupy Feya. Naszą specjalizacją jest dystrybucja domowych i przemysłowych maszyn do szycia legendarnej europejskiej marki Minerva.</p></div>
                    
                    <div id="animation-about-2" className="flex-main center column"><i className="fas fa-coins about-icon"></i><p className="about-paragraph">Revorm to firma o 100% europejskim kapitale. Naszą największą wartością realizowaną poprzez najwyższej jakości produkty oraz usługi jest satysfakcja klienta.</p></div>
                    
                    <div id="animation-about-3" className="flex-main center column"><i className="fas fa-globe-europe about-icon"></i><p className="about-paragraph">Dzięki ulokowaniu firmy oraz magazynów w centrum Europy jesteśmy w stanie szybko i sprawnie realizować dostawy na terenie Unii Europejskiej oraz po za nią.</p></div>
                </div>
            </div>
         
            <div className="flex-main logos wrap">
                <div ><img className="logo-image" id="animation-2" src="assets/logo.jpg"/></div>
                <div><a href="http://minerva-sewing.pl/about/" target="_blank"><img className="logo-image" id="animation-3" src="assets/minerva.png"/></a></div>
            </div>
        </div>
    </section>

    <section className="section">
        <div className="flex-main center photo offer"><div className="mask"></div></div>
        <h1 id="offer" className=" flex-main center photo-header">OFERTA</h1>
        <div className="content flex-main column center">
            <a id="animation-4" className="offer-section" href="http://minerva-sewing.pl/category/computer-machines/" target="_blank">
                <div className="offer-text">Domowe maszyny do szycia</div>
                <div className="img flex-main center"><img className="offer-image" src='assets/domowe.jpg'/></div>
            </a>
            <a id="animation-5" className="offer-section" href="http://minerva-sewing.pl/category/maszyny-przemyslowe/" target="_blank">
                <div className="img flex-main center"><img className="offer-image" src='assets/przemyslowe.jpg'/></div>
                <div className="offer-text">Przemysłowe maszyny do szycia</div>
            </a>
            
            <div id="animation-6" className="margin-top flex-main column center">
                <h2 style={{textAlign: "center"}}>Sprawdź całą naszą ofertę!</h2>
                <a href="http://www.minerva-sewing.pl/machine" target="_blank"><button className="button">OFERTA</button></a>
            </div>
        </div>
    </section>
    
    <section className="section">
        <div className="flex-main center photo contact"><div className="mask"></div></div>
        <h1 id="contact" className=" flex-main center photo-header">KONTAKT</h1>
        <div className="content flex-main around wrap">
            <div className="logo-contact flex-main center"><img src="assets/logo.jpg"/></div>
            <div className="flex-main between contact-container wrap">
                <div id="animation-7" className="flex-main column start contact-column">
                    <div className=""><a href="tel:48697325214" className="section-text flex-main baseline contact-header"><i className="contact-icon fas fa-phone-alt"></i><div style={{textDecoration: "underline"}}>+ 48 697 325 214</div></a></div>
                    <div className=""><a href="mailto:rmarczak@revorm.eu" className="section-text flex-main baseline contact-header"><i className="contact-icon fas fa-envelope"></i><div style={{textDecoration: "underline"}}>rmarczak@revorm.eu</div></a></div>
                    <div className="white contact-header"><b>Adres do korespondencji:</b></div>
                        <p className="paragraph-contact white">Revorm sp. z o.o.</p>
                        <p className="paragraph-contact white">ul. Kobierzycka 10</p>
                        <p className="paragraph-contact white">52-315 Wrocław</p>
                </div>
                <div id="animation-8" className="flex-main column smaller-contact register-column">
                    <div className="white contact-header"><b>Dane rejestrowe:</b></div>
                        <div className="paragraph-contact-smaller white">Revorm sp. z o.o.</div>
                        <div className="paragraph-contact-smaller white">ul. Przemysłowa 6</div>
                        <div className="paragraph-contact-smaller white">52-333 Wrocław</div>
                        <div className="paragraph-contact-smaller white">NIP / VATIN: 8992883709</div>
                        <div className="paragraph-contact-smaller white">ID SISC / EORI: PL899288370900000</div>
                        <div className="paragraph-contact-smaller white">REGON: 386743851</div>
                        <div className="paragraph-contact-smaller white">KRS: 0000853466</div>
                        <div className="paragraph-contact-smaller white">BDO: 000516315</div>
                </div>
            </div>
            <div id="animation-9" className="flex-main center">
                <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.5234720536405!2d16.98244651582056!3d51.06188617956444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc3c4fa749e67%3A0x180188ebf904e60f!2sKobierzycka%2010%2C%2052-315%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1619705297013!5m2!1spl!2spl" width="600px" height="450px" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            
        </div>
    </section>
    <footer className="footer flex-main column">
        <div className="flex-main center">
            <a href="https://www.facebook.com/Minerva.Polska/" target="_blank"><i className="fab fa-facebook-square icon"></i></a>
            <a href="https://www.instagram.com/minerva_sewing_polska/" target="_blank"><i className="fab fa-instagram-square icon"></i></a>
            <a href="https://www.youtube.com/channel/UCkIRzjiV5C43QrWjcJzNeJg" target="_blank"><i className="fa fa-youtube-square icon"></i></a>
        </div>
        <div className="sign flex-main center">Revorm 2021 </div>
    </footer>
    <a href="#" className="icon-up"><i className="fas fa-arrow-circle-up"></i></a>
</div>
</div>
    )
}

export default MainSitePl