import React from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../helpers/cookies';
import { updateAdmin, updatePasswordAdmin } from '../actions';
import Message from './Message';

class AccountAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: this.props.admin.login,
            email: this.props.admin.email,
            currentPasswordUpdate: '',
            currentPassword: '',
            password: '',
            confirmPassword: '',
            showMessage: false,
            message: ''
        }
    }

    handleChange = e => {
        switch(e.target.name) {
            case 'login':
                this.setState({login: e.target.value});
                break;
            case 'email':
                this.setState({email: e.target.value});
                break;
            case 'currentPasswordUpdate':
                this.setState({currentPasswordUpdate: e.target.value});
                break;
            case 'currentPassword':
                this.setState({currentPassword: e.target.value});
                break;
            case 'password':
                this.setState({password: e.target.value});
                break;
            case 'confirmPassword':
                this.setState({confirmPassword: e.target.value});
                break;
        }
    }

    showMessage = async (message, isError) => {
      this.setState({showMessage: false});
      this.setState({message: message});
      if(isError) {
      this.setState({isError: isError})
      } else {
      
      this.setState({isError: false})
      }
      this.setState({showMessage: true});
      setTimeout( () => {
          this.setState({showMessage: false})
          this.setState({message: ''})
      }, 5000);
  }

    handleUpdate = async e => {
      e.preventDefault();
      const token = getCookie('jwt_access_tkn');
      const id = getCookie('adminID');
      try {
      await this.props.updateAdmin(id, this.state.login, this.state.email, this.state.currentPasswordUpdate, token);
      if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl )};
      } catch(err) {
          if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }
      }
  }

  handlePassword = async e => {
    e.preventDefault();
    const token = getCookie('jwt_access_tkn');
    const id = getCookie('adminID');
    try {
    await this.props.updatePasswordAdmin(id, this.state.currentPassword, this.state.password, this.state.confirmPassword, token);
    if(this.props.language.language==="en") {
        this.showMessage(this.props.message.en) }
        else { this.showMessage(this.props.message.pl )};    } 
    catch(err) {
      if(this.props.language.language==="en") {
            this.showMessage(err.response.data.en, true);
          } else {
            this.showMessage(err.response.data.pl, true);
          }    }
  }

    render() {
      const { language } = this.props.language;
        return(
            <><form>
            <div className="mb-3">
              <label for="login-input" className="form-label">Login</label>
              <input value={this.state.login} onChange={this.handleChange} type="text" className="form-control" name="login" id="login-input"/>
            </div>
            <div className="mb-3">
              <label for="email-input" className="form-label">Email</label>
              <input value={this.state.email} onChange={this.handleChange} type="email" className="form-control" name="email" id="email-input"/>
            </div>
            <div className="mb-3">
              <label for="currentPasswordUpdate-input" className="form-label">
              {language==="en" ?
                <>Current password</> :
                <>Podaj obecne hasło</>}
                </label>
              <input onChange={this.handleChange} type="password" className="form-control" name="currentPasswordUpdate" id="currentPasswordUpdate-input"/>
            </div>
            <button onClick={this.handleUpdate} type="submit" className="btn btn-primary">
            {language==="en" ?
                <>Save</> :
                <>Zapisz</>}
              </button>
          </form>
          <form>
            <div className="mb-3">
              <label for="currentPassword-input" className="form-label">
              {language==="en" ?
                <>Current password</> :
                <>Podaj obecne hasło</>}
                </label>
              <input onChange={this.handleChange} type="password" className="form-control" name="currentPassword" id="currentPassword-input"/>
            </div>
            <div className="mb-3">
              <label for="password-input" className="form-label">
              {language==="en" ?
                <>New password</> :
                <>Podaj nowe hasło</>}
                </label>
              <input onChange={this.handleChange} type="password" className="form-control" name="password" id="password-input"/>
            </div>
            <div className="mb-3">
              <label for="confirmPassword-input" className="form-label">
              {language==="en" ?
                <>Confirm new password</> :
                <>Potwierdź nowe hasło</>}
                </label>
              <input onChange={this.handleChange} type="password" className="form-control" name="confirmPassword" id="confirmPassword-input"/>
            </div>
            <button onClick={this.handlePassword} type="submit" className="btn btn-primary">
            {language==="en" ?
                <>Change password</> :
                <>Zmień hasło</>}
              </button>
          </form>
          {this.state.showMessage ? <Message message={this.state.message} isError={this.state.isError}/> : null}

          </>

        )
    }
}

const mapStateToProps = (state) => {
  return { admin: state.admin, language: state.language, message: state.message };

};

export default connect(
  mapStateToProps,
  { updateAdmin, updatePasswordAdmin }
  )(AccountAdmin);