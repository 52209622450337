import React from 'react';
import { baseURL } from '../apis';
import { useSelector } from 'react-redux';
import { getCookie } from '../helpers/cookies';

function NavbarToggle() {
  let navbar = document.getElementById('navbarNav');
  if(navbar.style.display==='flex') {
    navbar.style.display='none'
  } else {
    navbar.style.display='flex'
  }
}

function Header({admin, isSigned, login, signOut, changeLanguage})  {

  const {language} = useSelector(state => state.language);

    if(admin) {
      if(isSigned) {
        return (
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
              <a className="navbar-brand" href={`${baseURL}/panel`}>
                {language==="en" ? 
                <>Administration Panel - Revorm</>: 
                <>Panel Administracyjny - Revorm</>}
              </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span onClick={NavbarToggle} className="navbar-toggler-icon"></span>
                </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href={`${baseURL}/panel/machines`}>
                      {language==="en" ?
                      <>Machines stock</> :
                      <>Stan maszyn</>}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={`${baseURL}/panel/machines/add`}>
                    {language==="en" ?
                      <>Add machine</> :
                      <>Dodaj maszynę</>}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={`${baseURL}/panel/reports`}>
                    {language==="en" ?
                      <>Reports</> :
                      <>Raporty</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={`${baseURL}/panel/administration`}>
                    {language==="en" ?
                      <>Administration</> :
                      <>Administracja</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={`${baseURL}/panel/account`}>
                    {language==="en" ?
                      <>Your account</> :
                      <>Twoje konto</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href='' onClick={signOut.bind(null)}>
                    {language==="en" ?
                      <>Sign out</> :
                      <>Wyloguj się</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link disabled">
                    {language==="en" ?
                      <>Signed in as {login}</> :
                      <>Zalogowany jako {login}</>}
                      </a>
                  </li>
                </ul>
              </div>

              {language==="en" ?
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="pl" src={`${baseURL}/pl.png`}/> :
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="en" src={`${baseURL}/en.png`}/>
              }
            </div>
          </nav>
        )
      } else {
        return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
              <a className="navbar-brand" href={`${baseURL}/panel`}>{language==="en" ?
              <>Administration Panel - Revorm</> :
              <>Panel Administracyjny - Revorm</>}
              </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span onClick={NavbarToggle} className="navbar-toggler-icon"></span>
                </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href={`${baseURL}/panel/`}>
                    {language==="en" ?
                      <>Sign in</> :
                      <>Zaloguj się</>}
                      </a>
                  </li>
                </ul>
              </div>
              {language==="en" ?
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="pl" src={`${baseURL}/pl.png`}/> :
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="en" src={`${baseURL}/en.png`}/>
              }
            </div>
          </nav>
        )
      }
    } else if(isSigned) {
      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href={`${baseURL}/machines`}>
                {language==="en" ?
                <>Inventory - Revorm</> :
                <>Stany magazynowe - Revorm</>}
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span onClick={NavbarToggle} className="navbar-toggler-icon"></span>
                </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href={`${baseURL}/machines/show`}>
                    {language==="en" ?
                      <>Machines stock</> :
                      <>Stan maszyn</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={`${baseURL}/machines/account`}>
                    {language==="en" ?
                      <>Your account</> :
                      <>Twoje konto</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href='' onClick={signOut.bind(null)}>
                    {language==="en" ?
                      <>Sign out</> :
                      <>Wyloguj się</>}
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link disabled">
                    {language==="en" ?
                      <>Signed in as {login}</> :
                      <>Zalogowany jako {login}</>}
                      </a>
                  </li>
                </ul>
              </div>
              {language==="en" ?
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="pl" src={`${baseURL}/pl.png`}/> :
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="en" src={`${baseURL}/en.png`}/>
              }
            </div>
          </nav>
      )
    } else {
      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href={`${baseURL}/machines`}>
                {language==="en" ?
                <>Inventory - Revorm</> :
                <>Stany magazynowe - Revorm</>}
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span onClick={NavbarToggle} className="navbar-toggler-icon"></span>
                </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href={`${baseURL}/machines`}>
                      {language==="en" ?
                      <>Sign in</> :
                      <>Zaloguj się</>}
                      </a>
                  </li>
                </ul>
              </div>
              {language==="en" ?
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="pl" src={`${baseURL}/pl.png`}/> :
                <img onClick={changeLanguage.bind(null)} className="flag-image" language="en" src={`${baseURL}/en.png`}/>
              }
            </div>
          </nav>
      )
}
}

export default Header